var _defineProperty = require("/home/circleci/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _EstimateStatusLabels, _EstimateStatusRankin, _StatusRanking, _CampaignStatusLabels, _MediaTypeRanking;

var _require = require('lodash/fp'),
    flow = _require.flow,
    words = _require.words,
    map = _require.map,
    capitalize = _require.capitalize,
    join = _require.join,
    pickBy = _require.pickBy;

var isNotEqualTo = function isNotEqualTo(values) {
  return function (target) {
    return !values.includes(target);
  };
};

var toPretty = flow(words, map(capitalize), join(' '));
var EstimateApprovalStatuses = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  LOADING: 'LOADING',
  ERROR: 'ERROR'
};
var EstimateStatuses = {
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  // change this to "Submitted"?
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  ACTUALISED: 'ACTUALISED'
};
var EstimateStatusLabels = (_EstimateStatusLabels = {}, _defineProperty(_EstimateStatusLabels, EstimateStatuses.APPROVED, 'Approved'), _defineProperty(_EstimateStatusLabels, EstimateStatuses.PENDING_APPROVAL, 'Pending Approval'), _defineProperty(_EstimateStatusLabels, EstimateApprovalStatuses.CHANGES_REQUESTED, 'Changes Requested'), _defineProperty(_EstimateStatusLabels, EstimateStatuses.DRAFT, 'Draft'), _defineProperty(_EstimateStatusLabels, EstimateStatuses.CANCELLED, 'Cancelled'), _defineProperty(_EstimateStatusLabels, EstimateStatuses.ACTUALISED, 'Actualised'), _EstimateStatusLabels);
var EstimateStatusRanking = (_EstimateStatusRankin = {}, _defineProperty(_EstimateStatusRankin, EstimateStatuses.ACTUALISED, 1), _defineProperty(_EstimateStatusRankin, EstimateStatuses.APPROVED, 2), _defineProperty(_EstimateStatusRankin, EstimateStatuses.PENDING_APPROVAL, 3), _defineProperty(_EstimateStatusRankin, EstimateStatuses.DRAFT, 4), _defineProperty(_EstimateStatusRankin, EstimateStatuses.CANCELLED, 5), _EstimateStatusRankin);
var StatusRanking = (_StatusRanking = {}, _defineProperty(_StatusRanking, EstimateStatuses.APPROVED, 1), _defineProperty(_StatusRanking, EstimateStatuses.PENDING_APPROVAL, 2), _defineProperty(_StatusRanking, EstimateStatuses.DRAFT, 3), _StatusRanking);
var CampaignStatuses = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  ACTUALISED: 'ACTUALISED'
};
var CampaignStatusLabels = (_CampaignStatusLabels = {}, _defineProperty(_CampaignStatusLabels, CampaignStatuses.OPEN, 'Open'), _defineProperty(_CampaignStatusLabels, CampaignStatuses.CLOSED, 'Closed'), _defineProperty(_CampaignStatusLabels, CampaignStatuses.ACTUALISED, 'Actualised'), _CampaignStatusLabels);
var MediaTypes = {
  TVC: 'TVC',
  DIGITAL_VIDEO: 'DIGITAL_VIDEO',
  AUDIO: 'AUDIO',
  DIGITAL: 'DIGITAL',
  // 'SOCIAL_ADS_AND_BANNER',
  PRINT: 'PRINT',
  RIGHTS: 'RIGHTS',
  MOBILE_AND_WEB_APPS: 'MOBILE_AND_WEB_APPS',
  OPERATIONAL: 'OPERATIONAL',
  ANIMATICS: 'ANIMATICS',
  CRM: 'CRM',
  AV: 'AV',
  // legacy
  SHOPPER: 'SHOPPER' // legacy

};
var MediaTypeRanking = (_MediaTypeRanking = {}, _defineProperty(_MediaTypeRanking, MediaTypes.AV, 1), _defineProperty(_MediaTypeRanking, MediaTypes.TVC, 1), _defineProperty(_MediaTypeRanking, MediaTypes.DIGITAL_VIDEO, 2), _defineProperty(_MediaTypeRanking, MediaTypes.AUDIO, 3), _defineProperty(_MediaTypeRanking, MediaTypes.DIGITAL, 4), _defineProperty(_MediaTypeRanking, MediaTypes.PRINT, 5), _defineProperty(_MediaTypeRanking, MediaTypes.RIGHTS, 6), _defineProperty(_MediaTypeRanking, MediaTypes.MOBILE_AND_WEB_APPS, 7), _defineProperty(_MediaTypeRanking, MediaTypes.OPERATIONAL, 8), _defineProperty(_MediaTypeRanking, MediaTypes.ANIMATICS, 9), _defineProperty(_MediaTypeRanking, MediaTypes.SHOPPER, 10), _defineProperty(_MediaTypeRanking, MediaTypes.CRM, 11), _MediaTypeRanking);
var SupplierApprovalStatus = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
};
var SupplierRequiredStatus = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  OPTIONAL: 'OPTIONAL',
  REQUIRED: 'REQUIRED'
};
var AvailableMediaTypes = pickBy(isNotEqualTo([MediaTypes.AV, MediaTypes.SHOPPER]), MediaTypes);
var DemoAdditionalTypes = [{
  value: 'EVENTS',
  label: 'Events'
}, {
  value: 'BRANDED_ENTERTAINMENT',
  label: 'Branded Entertainment'
}, {
  value: 'INFLUENCERS',
  label: 'Influencers'
}, {
  value: 'SPORT',
  label: 'Sport'
}];
var MediaTypeLabels = {
  AV: 'Video',
  DIGITAL_VIDEO: 'Digital Video',
  AUDIO: 'Audio Content',
  TVC: 'TVC',
  SHOPPER: 'Shopper',
  DIGITAL: 'Digital & Social Ads',
  RIGHTS: 'Celebrity & Usage Rights Renewals',
  PRINT: 'Print',
  ANIMATICS: 'Animatics',
  OPERATIONAL: 'Operational',
  MOBILE_AND_WEB_APPS: 'Mobile Apps & Websites',
  CRM: 'CRM'
};

var isApproved = function isApproved(estimate) {
  return estimate.status === EstimateStatuses.APPROVED;
};

var UsageRightsMediaTypes = {
  STOCK_IMAGE_FOOTAGE_MUSIC: 'STOCK_IMAGE_FOOTAGE_MUSIC',
  AGENCY_PAID_CELEBRITY: 'AGENCY_PAID_CELEBRITY',
  ADVERTISER_PAID_CELEBRITY: 'ADVERTISER_PAID_CELEBRITY'
};
var ProductionComplexityOrder = ['Simple', 'Standard', 'Complex'];
var ProductionComplexity = {
  SIMPLE: 'Simple',
  STANDARD: 'Standard',
  COMPLEX: 'Complex'
};
var EntityTypes = {
  ESTIMATE: 'ESTIMATE'
};
var BudgetSources = {
  ADVERTISING: 'Advertising',
  ECOMMERCE: 'Ecommerce',
  SHOPPER: 'Shopper',
  PROFESSIONAL: 'Professional',
  WEB_INFRASTRUCTURE: 'Web Infrastructure'
};
var UserStatuses = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  CREATED: 'CREATED'
};
var BudgetCenter = {
  LOCAL: 'Local',
  REGIONAL: 'Regional',
  GLOBAL: 'Global'
};
var YearField = {
  startYear: 2019,
  length: 12
};
var ApprovalType = {
  CLIENT: 'CLIENT',
  AGENCY: 'AGENCY'
};
var DeliverableOriginalTypes = {
  ORIGINAL: 'Original',
  ADDITIONAL: 'Additional',
  CLONE: 'Clone',
  RE_SHOOT: 'Re-shoot',
  TAG: 'Tag'
};
var clientSubdomains = {
  MCA: 'mca',
  CP: 'colgate',
  Demo: 'demo'
};
var AgencyLabel = {
  WPP: 'WPP',
  NON_WPP: 'NON WPP',
  COLGATE: 'Colgate'
};
module.exports = {
  toPretty: toPretty,
  isApproved: isApproved,
  EstimateStatuses: EstimateStatuses,
  EstimateStatusLabels: EstimateStatusLabels,
  EstimateStatusRanking: EstimateStatusRanking,
  EstimateApprovalStatuses: EstimateApprovalStatuses,
  CampaignStatuses: CampaignStatuses,
  CampaignStatusLabels: CampaignStatusLabels,
  StatusRanking: StatusRanking,
  MediaTypes: MediaTypes,
  MediaTypeRanking: MediaTypeRanking,
  AvailableMediaTypes: AvailableMediaTypes,
  MediaTypeLabels: MediaTypeLabels,
  UsageRightsMediaTypes: UsageRightsMediaTypes,
  ProductionComplexity: ProductionComplexity,
  ProductionComplexityOrder: ProductionComplexityOrder,
  EntityTypes: EntityTypes,
  DemoAdditionalTypes: DemoAdditionalTypes,
  BudgetSources: BudgetSources,
  UserStatuses: UserStatuses,
  BudgetCenter: BudgetCenter,
  YearField: YearField,
  ApprovalType: ApprovalType,
  DeliverableOriginalTypes: DeliverableOriginalTypes,
  clientSubdomains: clientSubdomains,
  SupplierApprovalStatus: SupplierApprovalStatus,
  SupplierRequiredStatus: SupplierRequiredStatus,
  AgencyLabel: AgencyLabel
};