import {
  compact,
  concat,
  filter,
  find,
  flow,
  get,
  getOr,
  groupBy,
  isEmpty,
  map,
  mapValues,
  set,
  size,
  some,
  uniqBy,
  reject,
  values,
  flatten,
} from 'lodash/fp';
import { createSelector } from 'reselect';
import config from 'config';
import { EstimateStatuses, EstimateApprovalStatuses, MediaTypes } from 'cr-core/constants';
import { countryToRegion } from 'components/forms/colgateRegions';
import { getAPIToken } from 'state/authentication/authService';
import {
  getUserId,
  getDisplayCurrency,
  getUser,
  getClientSettings,
} from 'state/authentication/selectors';
import { getEstimateApprovalsFor } from 'state/estimateApprovals/selectors';
import { getUserWorkspaceById } from 'state/workspaces/selectors';
import { AvailableMediaTypes, AccountSetting, MediaTypeLabels } from 'cr-core/constants';
import { ApprovalType, DemoAdditionalTypes } from 'cr-core/constants';

const getCampaignsById = get('campaigns.byId'); // HACK FOR NOW to stop cyclic imports

const { apiEndpoint } = config;

const statusesThatCanBeSubmitted = [EstimateStatuses.DRAFT, EstimateStatuses.PENDING_APPROVAL];

const mediaTypesWithEdits = [
  MediaTypes.AV,
  MediaTypes.TVC,
  MediaTypes.DIGITAL_VIDEO,
  MediaTypes.AUDIO,
  MediaTypes.ANIMATICS,
  MediaTypes.DIGITAL,
  MediaTypes.MOBILE_AND_WEB_APPS,
  MediaTypes.PRINT,
  MediaTypes.CRM,
  ...DemoAdditionalTypes.map(({ value }) => value),
];
const mediaTypeRequireEdits = type => mediaTypesWithEdits.includes(type);

export const setRegion = estimate =>
  set('region', getOr('UNKNOWN', estimate.leadMarket, countryToRegion), estimate);

export const setCampaign = campaignsById => estimate => {
  if (estimate.campaignId) {
    estimate.campaign = campaignsById[estimate.campaignId];
  }
  return estimate;
};

const _getEstimateById = estimateId => get(`estimates.byId.${estimateId}`);

export const getEstimatesById = get('estimates.byId');

export const getEstimatesList = get('estimates.list');

export const getEstimatesByCampaignId = campaignId =>
  flow(getEstimatesList, filter({ campaignId }));

export const getCampaignEstimates = campaignId => state => {
  const campaignEstimates = get('estimates.campaignEstimates', state);
  return campaignEstimates.campaignId === campaignId ? campaignEstimates.list : [];
};

export const getEstimateCost = estimate => quoteCurrency => {
  const { status, recommendedBidTotal, actualisedTotal } = estimate;
  const total = status === EstimateStatuses.ACTUALISED ? actualisedTotal : recommendedBidTotal;
  return { highest: total || 0, lowest: total || 0, quoteCurrency };
};

export const getEstimateCostById = estimateId =>
  createSelector(
    _getEstimateById(estimateId),
    getDisplayCurrency,
    (estimate, quoteCurrency) => estimate && getEstimateCost(estimate)(quoteCurrency)
  );

export const getRevision = ({ version }) => {
  return version - 1;
};

export const getEstimates = createSelector(
  getEstimatesList,
  getDisplayCurrency,
  getCampaignsById,
  (estimates, displayCurrency, campaigns) =>
    flow(
      values,
      map(estimate =>
        flow(
          set('costs', getEstimateCost(estimate)(displayCurrency)),
          setRegion,
          setCampaign(campaigns)
        )(estimate)
      )
    )(estimates)
);

export const getEstimateById = estimateId =>
  createSelector(
    _getEstimateById(estimateId),
    getCampaignsById,
    getDisplayCurrency,
    (estimate, campaignsById, displayCurrency) => {
      if (!estimate) {
        return;
      }

      estimate.campaign = campaignsById[estimate.campaignId];
      estimate.costs = getEstimateCost(estimate)(displayCurrency);
      estimate.region = getOr('UNKNOWN', estimate.leadMarket, countryToRegion);
      return estimate;
    }
  );

export const isBidIdRecommendedOnEstimate = (bidId, estimate) =>
  bidId === get('recommendedBid', estimate);

export const getEstimatesFiltersValues = get('form.estimateFilter.values');

export const getEstimatesPaginations = get('estimates.pagination');

export const getWorkspaceIdForEstimate = estimateId =>
  flow(getEstimateById(estimateId), get('workspaceId'));

export const getExportEstimatesWithLineItemsUrl = (year, accountId, currency, clientId) => {
  const token = getAPIToken();
  return `${apiEndpoint}/estimates/export?token=${token}&limit=10000&withLineItems=true&displayCurrency=${currency}&reportingYear=${year}&accountId=${accountId}&clientId=${clientId}`;
};

export const getExportEstimatesUrl = (year, currency, clientId) => {
  const token = getAPIToken();
  let url = `${apiEndpoint}/estimates/export?token=${token}&limit=100000&displayCurrency=${currency}&clientId=${clientId}`;
  if (year) {
    url += `&reportingYear=${year}`;
  }
  return url;
};

export const getScriptTitlesForEstimate = estimateId => state => {
  const estimate = getEstimateById(estimateId)(state);
  const scriptTitles = get('externalMetadata.scriptTitles', estimate);
  if (!isEmpty(scriptTitles)) {
    return scriptTitles;
  }

  const spotTitle = get('externalMetadata.spotTitle', estimate);
  if (spotTitle) {
    return [spotTitle];
  }
};

export const getApprovers = estimateId =>
  flow(_getEstimateById(estimateId), get('approvals'), map('approver'));

export const getEstimateStatusFor = estimateId => flow(_getEstimateById(estimateId), get('status'));

export const canUserApproveEstimate = estimateId =>
  createSelector(
    getUserId,
    getEstimateApprovalsFor(estimateId),
    getEstimateStatusFor(estimateId),
    (userId, approvals, status) =>
      status === EstimateStatuses.PENDING_APPROVAL &&
      some(
        ({ approverId, requestApprovalEmailSent, status }) =>
          status !== EstimateApprovalStatuses.APPROVED &&
          approverId === userId &&
          requestApprovalEmailSent,
        approvals
      )
  );

export const canRequestChanges = estimateId =>
  createSelector(
    getUserId,
    getEstimateApprovalsFor(estimateId),
    getEstimateStatusFor(estimateId),
    (userId, approvals, status) =>
      status === EstimateStatuses.PENDING_APPROVAL &&
      some(
        ({ approverId, requestApprovalEmailSent, status, approvalType }) =>
          status !== EstimateApprovalStatuses.APPROVED &&
          approverId === userId &&
          requestApprovalEmailSent &&
          approvalType === ApprovalType.CLIENT,
        approvals
      )
  );

export const getEstimateApprovedApprovalsCount = estimateId =>
  createSelector(
    getEstimateApprovalsFor(estimateId),
    flow(filter({ status: EstimateApprovalStatuses.APPROVED }), size)
  );

export const getEstimateStatus = estimateId =>
  createSelector(getEstimateById(estimateId), get('status'));

export const canUserSubmitEstimate = estimateId =>
  createSelector(getEstimateStatusFor(estimateId), status =>
    statusesThatCanBeSubmitted.includes(status)
  );

export const getApprovalsForEstimate = flow(
  get('approvals'),
  reject(approval =>
    [EstimateApprovalStatuses.LOADING, EstimateApprovalStatuses.ERROR].includes(approval.status)
  ),
  groupBy('status'),
  mapValues(size)
);

export const getEstimateApprovalTotals = estimateId =>
  createSelector(getEstimateById(estimateId), getApprovalsForEstimate);

export const getTeamForEstimate = estimate =>
  flow(
    concat([get('creator', estimate)]),
    concat(flow(get('approvals'), map('approver'))(estimate)),
    compact,
    uniqBy('id')
  )([]);

export const getEstimateWarnings = estimateId =>
  flow(
    getEstimateById(estimateId),
    ({ mediaType, recommendedBid, bids, externalMetadata, customData }) =>
      compact([
        !find({ id: recommendedBid }, bids) && 'Recommended bid not selected',
        mediaTypeRequireEdits(mediaType) &&
          !get('scriptTitles.length', externalMetadata) &&
          'Script Titles not set',
        !get('additionalMarkets.length', customData) && 'Additional markets not selected',
      ])
  );

const relatedLineItemsHasValues = (estimate, lineItemName, bidId) =>
  !!estimate?.bidValues
    ?.filter(
      item =>
        item.bidId === bidId &&
        lineItemName.relations.map(({ id }) => id).includes(item.lineItemNameId)
    )
    ?.some(item =>
      item.type === 'costSupplier'
        ? item.value.amount || item?.lineItemName?.lineItemSuppliers?.length
        : item.type === 'cost'
        ? item.value.amount
        : item.value
    );

const isValid = (bidValue, estimate, lineItemName, bidId) =>
  bidValue?.type === 'costSupplier'
    ? (bidValue?.value.amount && bidValue?.lineItemName?.lineItemSuppliers?.length) ||
      (!bidValue?.value.amount &&
        !bidValue?.lineItemName?.lineItemSuppliers?.length &&
        !relatedLineItemsHasValues(estimate, lineItemName, bidId))
    : bidValue?.type === 'cost'
    ? bidValue?.value.amount || !relatedLineItemsHasValues(estimate, lineItemName, bidId)
    : bidValue?.value || !relatedLineItemsHasValues(estimate, lineItemName, bidId);

export const hasLineItemsErrors = estimateId => state => {
  const clientSettings = getClientSettings(state);
  const estimate = getEstimateById(estimateId)(state);

  if (!clientSettings[AccountSetting.Suppliers] || !estimate?.requireLineItemChecks) {
    return false;
  }

  const hasErrors = estimate.bids.some(bid => {
    const valuesById = Object.fromEntries(
      estimate?.bidValues
        .filter(({ mandatory, bidId }) => mandatory && bidId === bid.id)
        .map(bidValue => [bidValue.lineItemNameId, bidValue])
    );

    const bidHasErrors = flatten(estimate?.lineItemGroups.map(item => item.lineItemNames))
      .filter(({ mandatory }) => mandatory)
      .some(lineItemName => !isValid(valuesById[lineItemName.id], estimate, lineItemName, bid.id));

    return bidHasErrors;
  });

  return hasErrors;
};

export const getEstimateFieldsError = estimateId => state => {
  const clientSettings = getClientSettings(state);
  const budgetCenterEnabled = clientSettings[AccountSetting.BudgetCenter];
  const budgetYearEnabled = clientSettings[AccountSetting.BudgetYear];

  return flow(
    getEstimateById(estimateId),
    ({
      mediaType,
      name,
      date,
      campaignId,
      products,
      numberOfRATVs,
      numberOfTVCs,
      edits,
      leadMarket,
      customData,
      budgetCenter,
      budgetYear,
      requireBudgetChecks,
    }) =>
      compact([
        !mediaType && 'Type',
        !name && 'Name',
        !date && 'Reporting date',
        !campaignId && 'Campaign',
        (!products || !products.length) && 'Products',
        !leadMarket && 'Lead Market',
        !get('payingCountries.length', customData) && 'Paying Countries',
        budgetCenterEnabled && requireBudgetChecks && !budgetCenter && 'Budget Center',
        budgetYearEnabled && requireBudgetChecks && !budgetYear && 'Budget Year',
        !get('budgetSource', customData) && 'Budget Source',
        mediaType !== MediaTypes.RIGHTS &&
          !get('productionComplexity', customData) &&
          'Production Complexity',
        mediaTypeRequireEdits(mediaType) &&
          !numberOfTVCs &&
          !numberOfRATVs &&
          'Number of Originals and/or Number of Edits (Deliverables)',
      ])
  )(state);
};

export const getEstimateDeliverablesError = estimateId =>
  flow(
    getEstimateById(estimateId),
    ({ mediaType, numberOfRATVs, numberOfTVCs, edits }) =>
      mediaTypeRequireEdits(mediaType) &&
      !numberOfTVCs &&
      !numberOfRATVs &&
      'Number of Originals and/or Number of Edits'
  );

export const getPoCodeError = estimateId => state => {
  const estimate = getEstimateById(estimateId)(state);
  const workspace =
    estimate && estimate.campaign && getUserWorkspaceById(estimate.campaign.workspaceId)(state);
  const campaigns = getCampaignsById(state);
  const campaign = campaigns[estimate.campaignId];
  const checkForMissingPo = workspace && workspace.account.accountSettings.poCodeWarning;
  const noEstimatePoCode = checkForMissingPo && !estimate.poCode;
  const noCampaignPoCode = checkForMissingPo && campaign && !campaign.poCode;

  return {
    hasError: noEstimatePoCode || noCampaignPoCode,
    noCampaignPoCode,
    noEstimatePoCode,
  };
};

export const getProductsForEstimate = get('products');

export const getCosts = (estimate, currency = 'usd') => {
  const exchangeRate = get(`exchangeRates.baseCurrencies.${currency}`, estimate);
  if (!exchangeRate) {
    return {};
  }
  return {
    estimated: exchangeRate * estimate.recommendedBidTotal,
    actualised: exchangeRate * estimate.actualisedTotal,
  };
};

export const getEstimateTypeOptions = createSelector(
  state => {
    const clientSettings = getClientSettings(state);
    const demoAdditionalTypesEnabled = clientSettings[AccountSetting.DemoAdditionalTypes];

    return { demoAdditionalTypesEnabled };
  },
  ({ demoAdditionalTypesEnabled }) => {
    const options = [
      ...Object.keys(AvailableMediaTypes).map(value => ({
        value,
        label: MediaTypeLabels[value],
      })),
      ...(demoAdditionalTypesEnabled ? DemoAdditionalTypes : []),
    ];

    return options;
  }
);

export const canEdit = estimateId => state => {
  if (typeof estimateId === 'undefined') {
    return false;
  }

  const estimate = getEstimateById(estimateId)(state);

  if (!estimate) {
    return false;
  }

  const creatorId = estimate.createdBy;
  const user = getUser(state);

  if (!user) {
    return false;
  }

  const userId = user.id;
  const workspaceId = estimate.campaign && estimate.campaign.workspaceId;

  const teams = {};

  (user.teams || []).forEach(team => {
    const workspace = (team.workspaces || []).find(({ id }) => id === workspaceId);

    if (workspace && !teams[team.id]) {
      teams[team.id] = team;
    }
  });

  const isAgencyUser = !!Object.values(teams).filter(team => team.account.agency).length;
  const canEdit = userId === creatorId || isAgencyUser || user.isConsultant;

  return canEdit;
};
