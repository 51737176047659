import { MediaTypes } from 'cr-core/constants';

const baseSizeInPx = 14;
export const remify = inputPx => `${inputPx / baseSizeInPx}rem`;

export const dimensions = {
  headerHeight: '90px',
  footerHeight: '80px',
};

export const inputHeight = '46px';

export const borderRadius = '4px';

export const colors = {
  white: 'white',
  primary: '#3f87b6',
  secondary: '#d8e7f0',
  success: '#2ec184',
  warning: '#f3b52b',
  error: '#ea4d4d',

  // warningBackground: 'rgba(243,181,43, 0.4)',
  recommendedColumn: '#bfd5e399',
  recommendedColumnHighlight: '#bfd5e377',
  button: '#3f87b6',
  buttonHover: '#2c74a3',
  approve: '#68bd0b',
  approveHover: '#59A209',
  cancel: '#bbbbbb',
  cancelHover: '#9b9b9b',
  text: '#333333',
  textLight: '#00000080',
  changeComment: '#F5E12377',
  containerBorder: '#d3d3d3',
  alert: '#F3552D',
  alertHover: '#B74D33',
  cancelChanges: '#7B1111',
  alternatingTableRow: 'rgba(0,0,0,.03)',
  warningBackground: '#f5a62333',
  lightBackground: '#f4f5f6',
  textInputPlaceholder: 'hsl(0,0%,50%)',
  placeholder: 'hsl(0,0%,50%)',
  border: 'hsl(0,0%,80%)',
  loading: '#f6f6f6',
  cancelled: '#E53935',
  itemHover: '#d8e7f0',

  //estimate statuses
  requestChanges: '#E91E63',
  requestChangesHover: '#d43a1a',
  pendingApproval: '#ff9800',
  draft: '#607D8B',
  approved: '#4caf50',
  actualised: '#6b974d',

  // budget statuses
  budgetLeft: '#519CF3',
  estimatedSpend: '#325581',
  overbudget: '#f47560',
  filteredOut: '#b1b1b1',

  // media types
  // https://flatuicolors.com/palette/au
  [MediaTypes.TVC]: '#447b2c',
  [MediaTypes.DIGITAL_VIDEO]: '#badc58',
  [MediaTypes.AUDIO]: '#ff7979',
  [MediaTypes.DIGITAL]: '#22a6b3', // 'SOCIAL_ADS_AND_BANNER',
  [MediaTypes.MOBILE_AND_WEB_APPS]: '#7ed6df',
  [MediaTypes.PRINT]: '#f0932b',
  [MediaTypes.RIGHTS]: '#30336b',
  [MediaTypes.OPERATIONAL]: '#686de0',
  [MediaTypes.ANIMATICS]: '#535c68',
  [MediaTypes.CRM]: '#535c68',

  [MediaTypes.AV]: '#4CAF50', // legacy
  [MediaTypes.SHOPPER]: '#ffbe76', // legacy
};

export const font = {
  fontFamily: 'Montserrat',
  sizes: {
    extraSmall: '9px',
    small: '10px',
    normal: '12px',
    largish: '14px',
    large: '16px',
    veryLarge: '20px',
    body: '12px',
    pageTitle: '22px',
    button: '14px',

    label: '16px',
  },
  weights: {
    thin: '300',
    regular: '400',
    bold: '600',
  },
};

export const shadows = {
  text: '0 1px 1px rgba(0, 0, 0, 0.16)',
  footerBar: '0 0 2px 1px rgba(0, 0, 0, 0.16);',
  inputBoxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.16)',
  menuHoverShadow: '0 1px 5px rgba(0,0,0,0.3)',
  modal: '0 1px 10px 5px rgba(0,0,0,0.16)',

  widget: '0 0px 3px -1px rgba(0,0,0,0.5)',
};

export const inputStyles = `
  height: ${inputHeight};
  padding: 0 12px;
  appearance: none;
  outline: none;
  border: 1px solid ${colors.border};
  border-radius: ${borderRadius};
  background-color: white;
  font-size: ${font.sizes.large};
  color: ${colors.text}
  letter-spacing: -0.4px;
  box-sizing: border-box;
  box-shadow: none;

  ::placeholder {
    color: ${colors.placeholder};
  }

  &[type='checkbox'] {
    appearance: checkbox;
  }

  &[type='number'] {
    width: 80px;
    padding-right: 7px;
  }
`;

export const textareaStyles = `
  ${inputStyles}

  padding: 10px 12px;
  resize: none;
  outline: none;
  box-shadow: ${shadows.inputBoxShadow};

  font-family: ${font.fontFamily};
  font-size: ${font.sizes.formInput};
  line-height: ${font.sizes.veryLarge};
`;

export const buttonStyles = `
  width: 100%;
  height: 55px;
  line-height: 48px;
  text-decoration: none;
  padding: 0 15px;
  margin: 0 5px;
  border: 0;
  font-weight: ${font.weights.normal};
  color: white;
  border-radius: 4px;
  font-family: ${font.fontFamily};
  font-size: ${font.sizes.button};
  font-weight: ${font.weights.normal};
  background-color: ${colors.button};
  text-align: left;
  font-size: ${font.sizes.button};
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  .icon {
    margin-right: 10px;
  }

  &:hover {
    color: white;
    background-color: ${colors.buttonHover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`;
