import React from 'react';
import clsx from 'clsx';
import { HistoryTypes, MediaTypes, MediaTypeLabels, DemoAdditionalTypes } from 'cr-core/constants';

import SvgAddPerson from './AddPerson';
import SvgAnimaticsIcon from './AnimaticsIcon';
import SvgApproveIcon from './ApproveIcon';
import SvgAttachmentsIcon from './AttachmentsIcon';
import SvgCameraIcon from './CameraIcon';
import SvgCopyrightIcon from './CopyrightIcon';
import SvgDraftIcon from './DraftIcon';
import SvgEditIcon from './EditIcon';
import SvgLaptopIcon from './LaptopIcon';
import SvgNewspaperIcon from './NewspaperIcon';
import SvgOperationalIcon from './OperationalIcon';
import SvgPendingIcon from './PendingIcon';
import SvgRadioIcon from './RadioIcon';
import SvgRemovePerson from './RemovePerson';
import SvgRequestChangesIcon from './RequestChangesIcon';
import SvgShoppingIcon from './ShoppingIcon';
import SvgTrashIcon from './TrashIcon';
import SvgWebAppsAndMobileIcon from './WebAppsAndMobileIcon';
import SvgCRMIcon from './SvgCRMIcon';
import SvgSportIcon from './SvgSportIcon';
import SvgBrandedEntertainmentIcon from './SvgBrandedEntertainmentIcon';
import SvgInfluencersIcon from './SvgInfluencersIcon';
import SvgEventsIcon from './SvgEventsIcon';

const additionalIcons = {
  SPORT: SvgSportIcon,
  BRANDED_ENTERTAINMENT: SvgBrandedEntertainmentIcon,
  INFLUENCERS: SvgInfluencersIcon,
  EVENTS: SvgEventsIcon,
};

const mediaTypeIconMap = {
  AV: { Icon: SvgCameraIcon, label: 'AV' },
  TVC: { Icon: SvgCameraIcon, label: 'TVC' },
  DIGITAL_VIDEO: { Icon: SvgCameraIcon, label: 'Digital Video' },
  AUDIO: { Icon: SvgRadioIcon, label: 'Audio' },
  SHOPPER: { Icon: SvgShoppingIcon, label: 'Shopper' },
  DIGITAL: { Icon: SvgLaptopIcon, label: 'Digital' },
  RIGHTS: { Icon: SvgCopyrightIcon, label: 'Talent & Usage Rights' },
  PRINT: { Icon: SvgNewspaperIcon, label: 'Print' },
  MOBILE_AND_WEB_APPS: { Icon: SvgWebAppsAndMobileIcon, label: 'Web Apps & Mobile' },
  ANIMATICS: { Icon: SvgAnimaticsIcon, label: 'Animatics' },
  OPERATIONAL: { Icon: SvgOperationalIcon, label: 'Operational' },
  [MediaTypes.CRM]: { Icon: SvgCRMIcon, label: MediaTypeLabels[MediaTypes.CRM] },
  ...Object.fromEntries(
    DemoAdditionalTypes.map(({ value, label }) => [value, { label, Icon: additionalIcons[value] }])
  ),
};

export const MediaTypeIcon = ({ type, className }) => {
  const unknown = { Icon: SvgDraftIcon, label: 'Unknown type' };
  const { Icon, label } = mediaTypeIconMap[type] || unknown;
  return <Icon className={clsx('icon', className)} title={label} />;
};

const historyTypeIconMap = {
  [HistoryTypes.ESTIMATE_CREATED]: { Icon: SvgDraftIcon, label: 'Estimate created' },
  [HistoryTypes.ESTIMATE_EDITED]: { Icon: SvgEditIcon, label: 'Estimate edited' },
  [HistoryTypes.BID_VALUE_EDITED]: { Icon: SvgEditIcon, label: 'Bid value edited' },
  [HistoryTypes.BID_ADDED]: { Icon: SvgEditIcon, label: 'Bid added' },
  [HistoryTypes.BID_DELETED]: { Icon: SvgTrashIcon, label: 'Bid deleted' },
  [HistoryTypes.SUBMITTED_FOR_APPROVAL]: {
    Icon: SvgRequestChangesIcon,
    label: 'Submitted for approval',
  },
  [HistoryTypes.CHANGES_REQUESTED]: { Icon: SvgPendingIcon, label: 'Changes requested' },
  [HistoryTypes.APPROVED]: { Icon: SvgApproveIcon, label: 'Approved' },
  [HistoryTypes.APPROVER_REMOVED]: { Icon: SvgRemovePerson, label: 'Approver removed' },
  [HistoryTypes.APPROVER_ADDED]: { Icon: SvgAddPerson, label: 'Approver removed' },
  [HistoryTypes.ATTACHMENT_ADDED]: { Icon: SvgAttachmentsIcon, label: 'Attachment added' },
  [HistoryTypes.ATTACHMENT_REMOVED]: { Icon: SvgTrashIcon, label: 'Attachment added' },
};

export const HistoryIcon = ({ type, className }) => {
  const unknown = { Icon: SvgDraftIcon, label: 'Unknown type' };
  const { Icon, label } = historyTypeIconMap[type] || unknown;
  return <Icon className={clsx('icon', className)} title={label} />;
};
