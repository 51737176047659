import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get, compose, isEmpty } from 'lodash/fp';
import { withHandlers } from 'recompose';
import { formValueSelector } from 'redux-form';
import { MediaTypes, MediaTypesWithDeliverables, AccountSetting } from 'cr-core/constants';
import { Events, track } from 'components/analytics';
import { ConfirmEditButton, CancelButton } from 'components/buttons';
import SvgCancelIcon from 'icons/CancelIcon';
import SvgSaveIcon from 'icons/SaveIcon';
import { colors } from 'shared/styles';
import { getEstimateById } from 'state/estimates/selectors';
import {
  EditCount,
  OriginalCount,
  ScriptTitles,
  PayingCountries,
  Products,
  Campaign,
  LeadMarket,
  AdditionalMarkets,
  BudgetSource,
  BudgetYear,
  EstimateName,
  Workspace,
  ReportingDate,
  Complexity,
  EstimateType,
  EstimatePOCode,
  BudgetCenterField,
  EstimateBundle,
} from './fields';
import { HorizontalLine } from '../horizontalLine';
import { getClientSettings } from 'state/authentication/selectors';

const estimateTypesWithScripts = [
  MediaTypes.AV,
  MediaTypes.TVC,
  MediaTypes.DIGITAL_VIDEO,
  MediaTypes.AUDIO,
  MediaTypes.ANIMATICS,
  MediaTypes.DIGITAL,
];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  color: ${colors.text};

  form {
    display: flex;
    flex-grow: 1;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 200px;
  flex-shrink: 0;

  .button {
    margin-bottom: 10px;
  }
`;

const Options = ({ scriptTitles, originalCount, editCount }) => (
  <>
    {scriptTitles && <ScriptTitles />}
    {Boolean(originalCount) && <OriginalCount />}
    {Boolean(editCount) && <EditCount />}
  </>
);

const isValid = (state, form, hasMultipleWorkspaces) => {
  const selectedMediaType = get('value', formValueSelector(form)(state, 'mediaType'));
  const selectedWorkspaceId = get('value', formValueSelector(form)(state, 'workspace'));
  const estimateName = formValueSelector(form)(state, 'estimateName');
  const campaign = formValueSelector(form)(state, 'campaign');
  return Boolean(
    selectedMediaType && estimateName && campaign && (hasMultipleWorkspaces || selectedWorkspaceId)
  );
};

const EstimateDetailForm = ({
  pristine,
  cancel,
  selectedMediaType,
  selectedWorkspaceId,
  selectedCampaignId,
  handleSubmit,
  hasMultipleWorkspaces,
  isValid,
  change,
  estimateId,
  clientSettings,
  initialValues,
  estimate = {},
}) => {
  const showOriginalEdits =
    estimateId &&
    (estimate.numberOfTVCs || estimate.numberOfTVCs) &&
    isEmpty(estimate.edits) &&
    isEmpty(estimate.additionalDeliverables);
  const optionalFields = {};
  if (MediaTypesWithDeliverables.includes(selectedMediaType)) {
    optionalFields.originalCount = showOriginalEdits;
    optionalFields.editCount = showOriginalEdits;
  }
  if (estimateTypesWithScripts.includes(selectedMediaType)) {
    optionalFields.scriptTitles = true;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FormColumn>
          {hasMultipleWorkspaces && <Workspace disabled={selectedCampaignId} />}
          <EstimateType />
          <EstimateName />
          <ReportingDate />
          <Campaign workspaceId={selectedWorkspaceId} formChange={change} />
          <Products workspaceId={selectedWorkspaceId} campaignId={selectedCampaignId} />
          {!isEmpty(optionalFields) && Options(optionalFields)}
          <HorizontalLine />
          <LeadMarket />
          <AdditionalMarkets />
          <PayingCountries />
          {clientSettings[AccountSetting.BudgetCenter] &&
            (estimate.requireBudgetChecks || !estimateId) && <BudgetCenterField />}

          {clientSettings[AccountSetting.BudgetYear] &&
            (estimate.requireBudgetChecks || !estimateId) && <BudgetYear />}

          <BudgetSource />
          <Complexity />
          <EstimatePOCode />
          {clientSettings[AccountSetting.EstimateBundle] && <EstimateBundle />}
        </FormColumn>
      </form>
      <ButtonsContainer>
        <CancelButton onClick={cancel}>
          <SvgCancelIcon className="icon" />
          Cancel
        </CancelButton>
        <ConfirmEditButton
          disabled={pristine || !isValid}
          onClick={handleSubmit}
          data-test="confirm-edit"
        >
          <SvgSaveIcon className="icon" />
          Save Changes
        </ConfirmEditButton>
      </ButtonsContainer>
    </Container>
  );
};

const mapStateToProps = (state, { form, hasMultipleWorkspaces, estimateId }) => ({
  selectedMediaType: get('value', formValueSelector(form)(state, 'mediaType')),
  selectedWorkspaceId: get('value', formValueSelector(form)(state, 'workspace')),
  selectedCampaignId: get('value', formValueSelector(form)(state, 'campaign')),
  isValid: isValid(state, form, hasMultipleWorkspaces),
  estimate: getEstimateById(estimateId)(state),
  clientSettings: getClientSettings(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withHandlers({
    cancel:
      ({ estimateId, resetForm, history }) =>
      () => {
        if (estimateId) {
          track(Events.ESTIMATE_EDIT_CANCEL, { estimateId });
          return resetForm();
        }
        track(Events.ESTIMATE_CREATE_CANCEL);
        history.push('/campaigns');
      },
  })
)(EstimateDetailForm);
