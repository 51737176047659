import { components } from 'react-select';
import React from 'react';
import { map, flow, flatten, keyBy } from 'lodash/fp';
import styled from 'styled-components';
import { Select } from 'components/forms';
import ColgateRegions, { decoratedCountries } from 'components/forms/colgateRegions';

export const countryOptions = flow(
  map(({ name: regionName, code: regionCode, countries }) => [
    { value: regionName, label: regionName, code: regionCode },
    ...map(
      ({ name: countryName, flag, countryCode }) => ({
        flag,
        countryCode,
        label: countryName,
        value: countryName,
        region: regionName,
      }),
      countries
    ),
  ]),
  flatten
)(ColgateRegions);

export const countries = flow(
  map(({ name: countryName, flag, countryCode }) => ({
    flag,
    countryCode,
    label: countryName,
    value: countryName,
  })),

  flatten
)(decoratedCountries);

export const countryOptionsByName = keyBy('label', countryOptions);

const CountryIcon = styled.img`
  height: 10px;
  display: inline;
  margin-right: 5px;
`;

export const CountryOption = props => {
  return (
    <components.Option {...props}>
      {props.data.flag && <CountryIcon src={props.data.flag} />}
      {props.data.label}
    </components.Option>
  );
};

export const CountrySingleValue = props => {
  return (
    <components.SingleValue {...props}>
      {props.data.flag && <CountryIcon src={props.data.flag} />}
      {props.data.label}
    </components.SingleValue>
  );
};

export const CountryMultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.flag && <CountryIcon src={props.data.flag} />}
      {props.data.label}
    </components.MultiValueLabel>
  );
};

const styles = {
  option: (provided, { data }) => ({
    ...provided,
    paddingLeft: data.region ? 20 : 10,
  }),
};

export const CountrySelect = ({ input, isClearable, resetFilter, ...props }) => (
  <Select
    options={countryOptions}
    value={input.value}
    components={{ Option: CountryOption, SingleValue: CountrySingleValue }}
    styles={styles}
    isClearable={isClearable}
    onChange={value => {
      if (value || !resetFilter) {
        return input.onChange(value);
      }
      resetFilter();
    }}
    {...props}
  />
);

export const CountrySelectMulti = ({ input, ...props }) => (
  <Select
    isMulti
    className="reactSelect__multi"
    options={countryOptions}
    onChange={input.onChange}
    value={input.value}
    components={{ Option: CountryOption, MultiValueLabel: CountryMultiValueLabel }}
    styles={styles}
    {...props}
  />
);
