import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, flow, get, find, map, filter, getOr, keyBy } from 'lodash/fp';
import { compose, getContext, withHandlers } from 'recompose';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import SvgCollapseIcon from 'icons/CollapseIcon';
import SvgExpandIcon from 'icons/ExpandIcon';
import { removeCostCategory } from 'state/costCategories/actions';
import { getCostCategoryById } from 'state/costCategories/selectors';
import { isCostCategoryEmpty, getBidIdsForEstimate } from 'state/bids/selectors';
import { AlternativeCurrencies } from './components';
import { Expander, Row, RowsContainer, RowTitle, Cell, ValueContainer } from './tableComponents';
import withAlternativeCurrencies from './withAlternativeCurrencies';
import withRecommendedBidSelected from './withRecommendedBidSelected';
import { getLastLineItemIdAddedCategory } from 'state/lineItems/selectors';
import { resetLastLineItemAdded } from 'state/lineItems/actions';

const CostCategoryBidTotalCell = compose(
  getContext({ estimate: PropTypes.object }),
  connect((state, { costCategoryId, bidId, estimate }) => {
    const costCategoryLineItemsIds = flow(
      getCostCategoryById(costCategoryId),
      getOr([], 'lineItemNames'),
      keyBy('id')
    )(state);

    return {
      values: flow(
        get('bidValues'),
        filter({ bidId }),
        filter(({ lineItemNameId }) => costCategoryLineItemsIds[lineItemNameId]),
        filter(({ type }) => type === 'cost' || type === 'costSupplier'),
        map('value')
      )(estimate),
    };
  }),
  withAlternativeCurrencies,
  withRecommendedBidSelected
)(
  ({
    total,
    alternativeCurrencies,
    displayCurrency,
    values,
    costCategoryId,
    bidId,
    estimate,
    ...props
  }) => (
    <Cell {...props} title={total ? formatAsCurrency(displayCurrency, total, 2) : null}>
      <ValueContainer data-test="costCategoryBidTotal">
        {!isEmpty(alternativeCurrencies) && (
          <AlternativeCurrencies>({alternativeCurrencies})</AlternativeCurrencies>
        )}
        {total ? formatAsCurrency(displayCurrency, total) : null}
      </ValueContainer>
    </Cell>
  )
);

const mapStateToProps = (state, { costCategoryId, estimate }) => ({
  shouldAskDeleteConfirmation: isCostCategoryEmpty(costCategoryId)(state),
  lastLineItemAddedCategory: getLastLineItemIdAddedCategory(state),
  costCategoryName: flow(
    get('lineItemGroups'),
    find({ id: costCategoryId }),
    get('name')
  )(estimate),
  bidIds: getBidIdsForEstimate(state),
});

const mapDispatchToProps = (dispatch, { estimate, costCategoryId, pageId }) => ({
  onDelete: () => dispatch(removeCostCategory(estimate.id, costCategoryId, pageId)),
  resetLastLineItemAdded: () => dispatch(resetLastLineItemAdded()),
});

const CostCategoryRow = compose(
  getContext({
    estimate: PropTypes.object,
    toggleExpanded: PropTypes.func,
    isExpanded: PropTypes.bool,
    readOnly: PropTypes.bool,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onToggleClick:
      ({ toggleExpanded, resetLastLineItemAdded }) =>
      () => {
        toggleExpanded();
        resetLastLineItemAdded();
      },
  })
)(
  ({
    costCategoryId,
    costCategoryName,
    toggleExpanded,
    isExpanded,
    readOnly,
    onDelete,
    dragHandleProps,
    shouldAskDeleteConfirmation,
    onToggleClick,
    estimate,
  }) => {
    const bidIds = map('id', estimate.bids);
    return (
      <RowsContainer enabled={!readOnly} onClick={onToggleClick}>
        <Row
          onDelete={onDelete}
          dragHandleProps={dragHandleProps}
          withDeleteConfirmation={shouldAskDeleteConfirmation}
          data-test="cost-category-row"
        >
          <Expander>{isExpanded ? <SvgCollapseIcon /> : <SvgExpandIcon />}</Expander>
          <RowTitle>{costCategoryName}</RowTitle>
          {bidIds.map(bidId => (
            <CostCategoryBidTotalCell key={bidId} costCategoryId={costCategoryId} bidId={bidId} />
          ))}
        </Row>
      </RowsContainer>
    );
  }
);

export default CostCategoryRow;
