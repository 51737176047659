import { sum, flow, map, sumBy } from 'lodash/fp';
import { CampaignStatusLabels } from 'cr-core/constants';
import { sortWithMultipleValues } from '../utils';
import { CurrencyValueCell, FooterValueCell } from '../components';
import { COLUMNS } from '../utils.legend';

export const campaignDivision = () => ({
  id: 'division',
  Header: 'Division',
  description: COLUMNS.campaignPayingDivision,
  accessor: 'division',
  PivotValue: ({ value }) => value,
  Footer: () => 'Total',
  sortType: sortWithMultipleValues,
  maxWidth: 130,
});

export const campaignPayingCountry = () => ({
  id: 'country',
  Header: 'Paying Country',
  accessor: 'country',
  description: COLUMNS.campaignPayingCountry,
  PivotValue: ({ value }) => value,
  Aggregated: () => '',
  sortType: sortWithMultipleValues,
});

export const campaignProductCategory = () => ({
  id: 'category',
  Header: 'Category',
  accessor: 'category',
  description: COLUMNS.campaignProductCategory,
  Cell: ({ value }) => value || '-',
  PivotValue: ({ value }) => value,
  Aggregated: () => '',
  sortType: sortWithMultipleValues,
});

export const campaignProductBrand = () => ({
  id: 'brand',
  Header: 'Brand',
  accessor: 'brand',
  description: COLUMNS.campaignProductBrand,
  Cell: ({ value }) => value || '-',
  PivotValue: ({ value }) => value,
  Aggregated: () => '',
  sortType: sortWithMultipleValues,
});

export const campaignName = () => ({
  id: 'campaign',
  Header: 'Campaign',
  accessor: 'name',
  description: COLUMNS.campaignName,
  Aggregated: () => '',
});

export const campaignStatus = () => ({
  id: 'status',
  Header: 'Campaign Status',
  description: COLUMNS.campaignStatus,
  accessor: 'status',
  Cell: ({ cell: { value } }) => CampaignStatusLabels[value],
  Aggregated: () => '',
  maxWidth: 80,
});

export const campaignBudget = ({ data, displayCurrency }) => ({
  id: 'budget',
  Header: 'Total Budget',
  description: COLUMNS.campaignBudget,
  accessor: 'budget',
  aggregate: flow(
    map(x => x || 0),
    sum,
  ),
  Cell: CurrencyValueCell(displayCurrency),
  Footer: () => FooterValueCell(sumBy('budget', data), displayCurrency),
  align: 'right',
  maxWidth: 100,
});

export const campaignLatestEstimate = ({ data, displayCurrency }) => ({
  id: 'estimated',
  Header: 'Estimated Spend',
  description: COLUMNS.campaignLatestEstimate,
  accessor: 'totalEstimated',
  aggregate: values =>
    flow(
      map(x => x || 0),
      sum,
    )(values),
  Cell: CurrencyValueCell(displayCurrency),
  Footer: () => FooterValueCell(sumBy('totalEstimated', data), displayCurrency),
  align: 'right',
  maxWidth: 100,
});

export const campaignVariance = ({ data, displayCurrency }) => ({
  id: 'variance',
  Header: 'Budget Left',
  description: COLUMNS.campaignVariance,
  accessor: 'variance',
  aggregate: flow(
    map(x => x || 0),
    sum,
  ),
  Cell: CurrencyValueCell(displayCurrency),
  Footer: () => FooterValueCell(sumBy('variance', data), displayCurrency),
  align: 'right',
  maxWidth: 100,
});

export const campaignActualised = ({ data, displayCurrency }) => ({
  id: 'final',
  Header: 'Actualised Spend',
  accessor: 'totalFinal',
  description: COLUMNS.campaignActualised,
  aggregate: flow(
    map(x => x || 0),
    sum,
  ),
  Cell: CurrencyValueCell(displayCurrency),
  Footer: () => FooterValueCell(sumBy('totalFinal', data), displayCurrency),
  align: 'right',
  maxWidth: 100,
});

export const campaignSavings = ({ data, displayCurrency }) => ({
  id: 'saving',
  Header: 'Savings',
  description: COLUMNS.campaignActualSavings,
  accessor: 'finalSaving',
  aggregate: flow(
    map(x => x || 0),
    sum,
  ),
  Cell: CurrencyValueCell(displayCurrency),
  Footer: () => FooterValueCell(sumBy('finalSaving', data), displayCurrency),
  align: 'right',
  maxWidth: 100,
});
