import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getEstimates } from 'state/estimates/selectors';
import { getCampaigns } from 'state/campaigns/selectors';
import { Message, ReportHeader } from '../components';
import Charts from './charts';
import Metrics from '../defaultMetrics';
import Table from './table';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));

const BudgetReport = ({ estimates, campaigns, displayCurrency, filters }) => {
  const classes = useStyles();
  const title = 'Campaigns by Division Report';

  return (
    <div className={classes.container}>
      <ReportHeader title={title} />
      {campaigns && !campaigns.length && <Message>No data for the selected filters</Message>}
      {Boolean(campaigns && campaigns.length) && (
        <>
          <Metrics
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            filters={filters}
          />
          <Charts estimates={estimates} campaigns={campaigns} displayCurrency={displayCurrency} />
          <Table
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            title={title}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  estimates: getEstimates(state),
  campaigns: getCampaigns(state),
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(BudgetReport);
