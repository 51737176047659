import queryString from 'query-string';
import moment from 'moment';
import { map, uniq, flow, forEach, isEqual, keys, isArray, omitBy, uniqBy } from 'lodash/fp';
import { getProducts } from 'state/products/selectors';

const filtersType = {
  search: 'string',
  mediaType: 'array',
  // productCategory: 'array',
  productBrand: 'array',
  estimateStatus: 'array',
  budgetYear: 'array',
  reportingYear: 'array',
  budgetSource: 'array',
  assignedToMe: 'boolean',
  leadMarket: 'array',
  productionComplexity: 'array',
  campaignStatus: 'array',
  workspaceId: 'array',
  additionalFilters: 'array',
  excludeCancelledEstimates: 'boolean',
  payingCountries: 'array',
  budgetCenter: 'array',
  clientIoNumber: 'array',
  campaignHumanId: 'array',
};

export const getCleanFilters = (filters, defaultFilters) =>
  flow(
    omitBy(
      (value, key) =>
        isEqual(value, defaultFilters[key]) ||
        value === false ||
        value === '' ||
        (isArray(value) && !value.length)
    )
  )(filters);

export const stringifyFilters = (filters, defaultFilters) => {
  const queryValues = { ...filters };

  if (queryValues.reportingDate) {
    queryValues.reportingDateStart = moment(queryValues.reportingDate.start).format('X');
    queryValues.reportingDateEnd = moment(queryValues.reportingDate.end).format('X');
    delete queryValues.reportingDate;
  }

  if (queryValues.brandCategories) {
    queryValues.brandCategories = JSON.stringify(queryValues.brandCategories);
  }

  return queryString.stringify(
    omitBy((value, key) => {
      return (
        isEqual(value, defaultFilters[key]) ||
        (value === false && key !== 'excludeCancelledEstimates')
      );
    }, queryValues), // remove default values
    { skipEmptyString: true }
  );
};

export const parseQueryFilters = search => {
  const filters = queryString.parse(search, { parseBooleans: true });

  forEach(key => {
    if (filtersType[key] === 'array') {
      if (isArray(filters[key])) {
        filters[key] = uniq(filters[key]);
      } else {
        filters[key] = [filters[key]];
      }
    }
  }, keys(filters));

  if (filters.reportingDateStart && filters.reportingDateEnd) {
    const start = moment.unix(filters.reportingDateStart);
    const end = moment.unix(filters.reportingDateEnd);
    if (start.isValid() && end.isValid()) {
      filters.reportingDate = {
        start: start.toDate(),
        end: end.toDate(),
      };
    }
    delete filters.reportingDateStart;
    delete filters.reportingDateEnd;
  }

  return filters;
};

export const getProductCategories = state => {
  return flow(getProducts, map('brandCategory'), uniq)(state);
};

export const getProductBrand = state => {
  return flow(
    getProducts,
    uniqBy(v => [v.brandName, v.brandCategory].join())
  )(state);
};
