import moment from 'moment';
import { fetch } from 'state/fetchMiddleware';
import { Events, track } from 'components/analytics';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getQueryFromFilters } from 'state/estimates/actions';
import axios from 'axios';
export const REPORT_COST_CATEGORIES_FETCH_REQUEST = 'reports/COST_CATEGORIES_FETCH_REQUEST';
export const REPORT_COST_CATEGORIES_FETCH_SUCCESS = 'reports/COST_CATEGORIES_FETCH_SUCCESS';
export const REPORT_DASHBOARD_REQUEST = 'reports/REPORT_DASHBOARD_REQUEST';
export const REPORT_DASHBOARD_SUCCESS = 'reports/REPORT_DASHBOARD_SUCCESS';

const CancelToken = axios.CancelToken;

let cancel;

export const fetchCostCategoriesReport = (query = {}, requestId) => async (dispatch, getState) => {
  try {
    if (cancel) {
      cancel();
    }

    const displayCurrency = getDisplayCurrency(getState());

    const filters = getQueryFromFilters(query);

    dispatch({ type: REPORT_COST_CATEGORIES_FETCH_REQUEST, query, requestId });

    const t = Date.now();

    const response = await dispatch(
      fetch(
        '/reports/cost-categories',
        {
          params: {
            ...filters,
            displayCurrency,
          },
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        }
      )
    );

    const data = response?.data?.data;

    if (data) {
      track(Events.PERFORMANCE, {
        metric: 'fetch_report_cost_categories',
        t: Date.now() - t,
      });

      dispatch({ type: REPORT_COST_CATEGORIES_FETCH_SUCCESS, data, query, requestId });
    }

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDashboardData = () => async (dispatch, getState) => {
  const displayCurrency = getDisplayCurrency(getState());
  dispatch({ type: REPORT_DASHBOARD_REQUEST });
  const t = Date.now();
  const { data } = await dispatch(
    fetch('/reports/dashboard', {
      params: {
        displayCurrency,
        reportingDateStart: moment()
          .startOf('year')
          .toISOString(),
        reportingDateEnd: moment()
          .endOf('year')
          .toISOString(),
      },
    })
  );
  track(Events.PERFORMANCE, {
    metric: 'fetch_report_dashboard',
    t: Date.now() - t,
  });
  dispatch({ type: REPORT_DASHBOARD_SUCCESS, data });
  return data;
};
