import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { track, getFiltersChangedData, Events } from 'components/analytics';
import { MainContent } from 'components/page';
import EstimateList from 'components/estimateList';
import EstimateActionBar from 'components/estimateList/actionBar';
import AppContainer from 'components/listPageContainer';
import { makeStyles } from '@material-ui/core/styles';
import Filters, { defaultFilters, parseQueryFilters } from 'components/filtersSidebar';

const initiallyDisplayedFilters = [
  'search',
  'workspaceId',
  'budgetYear',
  'reportingYear',
  'assignedToMe',
  'categoryBrand',
  'estimateStatus',
  'mediaType',
  'payingCountries',
  'excludeCancelledEstimates',
];

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    maxWidth: 310,
  },
}));

const EstimatesPage = ({ history, location }) => {
  const classes = useStyles();
  const [pageId] = useState(uuid());
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...parseQueryFilters(location.search),
  });

  const onChangeFilters = (values, prevValues) => {
    setFilters(values);
    track(Events.FILTERS_CHANGED, {
      page: 'estimates',
      ...getFiltersChangedData(values, prevValues),
    });
  };

  return (
    <AppContainer>
      <div className={classes.filtersContainer}>
        <Filters
          pageId={pageId}
          onChange={onChangeFilters}
          filters={filters}
          initiallyDisplayedFilters={initiallyDisplayedFilters}
        />
      </div>
      <MainContent>
        <EstimateList pageId={pageId} filters={filters} />
      </MainContent>
      <EstimateActionBar />
    </AppContainer>
  );
};

export default connect()(EstimatesPage);
