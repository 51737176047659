import { compact } from 'lodash/fp';
import {
  estimateName,
  estimateRegion,
  estimateLeadMarket,
  estimateOriginals,
  estimateEdits,
  estimateLatestEstimate,
  estimateActualised,
  estimateAverage,
  estimateBundle,
} from '../utils.columns';

import * as donwloadColumnsUtils from '../utils.downloadColumns';
import { AccountSetting } from 'cr-core/constants';

const columns = (data, displayCurrency, { originals, edits }, clientSettings) => {
  const columns = [
    estimateRegion(),
    estimateLeadMarket(),
    estimateName(),
    estimateOriginals({ originals }),
    estimateEdits({ edits }),
    estimateLatestEstimate({ displayCurrency }),
    estimateActualised({ displayCurrency }),
    estimateAverage({ displayCurrency }),
  ];

  clientSettings[AccountSetting.EstimateBundle] && columns.splice(3, 0, estimateBundle());

  return columns;
};

export default columns;

export const downloadColumns = [
  ...donwloadColumnsUtils.estimateInfo(),
  donwloadColumnsUtils.estimateBudgetCenter,
  donwloadColumnsUtils.estimateOriginals,
  donwloadColumnsUtils.estimateEdits,
  donwloadColumnsUtils.estimateBundle,
];

export const getDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    ...donwloadColumnsUtils.estimateInfo(clientSettings),
    clientSettings[AccountSetting.BudgetCenter] && donwloadColumnsUtils.estimateBudgetCenter,
    donwloadColumnsUtils.estimateOriginals,
    donwloadColumnsUtils.estimateEdits,
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
  ]);
