import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty, flow, map, get, flatten, find, compact } from 'lodash/fp';
import PropTypes from 'prop-types';
import { compose, getContext, withProps, branch, renderComponent } from 'recompose';
import { formatAsCurrency } from 'cr-core/currencyUtils';
import EditableCostCell from 'components/estimateBidsTable/editableCostCell';
import { isLineItemLineEmpty } from 'state/bids/selectors';
import { removeLineItem } from 'state/lineItems/actions';
import { getLastLineItemIdAdded } from 'state/lineItems/selectors';
import { AlternativeCurrencies } from './components';
import EditableCell from './editableCell';
import EditableDropdownCell from './editableDropdownCell';
import EditableCountryDropdownCell from './editableCountrySelectCell';
import { Cell, Row, RowTitle, ValueContainer } from './tableComponents';
import withAlternativeCurrencies from './withAlternativeCurrencies';
import withEditableness from './withEditableness';
import withForwardingRef from './withForwardingRef';
import withRecommendedBidSelected from './withRecommendedBidSelected';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { getClient, getClientSettings } from 'state/authentication/selectors';
import { AccountSetting } from 'cr-core/constants';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import EditableCostSuppliersCell from './EditableCostSuppliersCell';

const EditCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId, value }) => {
    return {
      estimateId: estimate.id,
      lineItemNameId: lineItemId,
      ...value,
    };
  }),
  branch(({ isEditing }) => isEditing, renderComponent(EditableCostCell))
);

const CostSuppliersEditCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId, value }) => {
    return {
      estimateId: estimate.id,
      lineItemNameId: lineItemId,
      ...value,
    };
  }),
  branch(({ isEditing }) => isEditing, renderComponent(EditableCostSuppliersCell))
);

const LineItemCostValueCell = compose(
  withProps(({ value }) => ({
    values: value ? [value] : [],
  })),
  getContext({ readOnly: PropTypes.bool }),
  withAlternativeCurrencies,
  withEditableness,
  connect(state => ({ client: getClient(state) })),
  branch(({ readOnly }) => !readOnly, EditCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    alternativeCurrencies,
    client,
    displayCurrency,
    total,
    edit,
    supplierRequiredStatus,
    suppliers,
    suppliersEnabled,
    clientId,
    errors = [],
    ...props
  }) => {
    return (
      <Cell {...props}>
        <Box width="100%" position="relative" display="flex" flexDirection="column" height="100%">
          <Errors errors={errors} />
          <ValueContainer
            onClick={edit}
            style={{ padding: '12px', height: '100%' }}
            title={total ? formatAsCurrency(displayCurrency, total, 2) : null}
          >
            <Box textAlign="right">
              {!isEmpty(alternativeCurrencies) && (
                <AlternativeCurrencies>({alternativeCurrencies})</AlternativeCurrencies>
              )}
              {total ? formatAsCurrency(displayCurrency, total) : '-'}
            </Box>
          </ValueContainer>
        </Box>
      </Cell>
    );
  }
);

const Errors = ({ errors = [] }) => {
  if (!errors.length) {
    return null;
  }

  return (
    <Box position="absolute" top="50%" left="10px" style={{ transform: 'translateY(-50%)' }}>
      <Tooltip
        arrow
        title={errors.map((error, index) => (
          <Box key={index}>{error}</Box>
        ))}
      >
        <Typography color="error" component="span">
          <ErrorOutlineOutlined />
        </Typography>
      </Tooltip>
    </Box>
  );
};

const LineItemCostSuppliersCell = compose(
  withProps(({ value }) => ({
    values: value ? [value] : [],
  })),
  getContext({ readOnly: PropTypes.bool }),
  withAlternativeCurrencies,
  withEditableness,
  connect(state => ({ client: getClient(state) })),
  branch(({ readOnly }) => !readOnly, CostSuppliersEditCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    alternativeCurrencies,
    client,
    displayCurrency,
    total,
    edit,
    supplierRequiredStatus,
    suppliers,
    suppliersEnabled,
    clientId,
    errors = [],
    ...props
  }) => {
    const suppliersValue = suppliers?.map(({ label }) => label)?.join(', ');

    return (
      <Cell {...props}>
        <Box
          width="100%"
          onClick={edit}
          position="relative"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Errors errors={errors} />
          {!!suppliers?.length && (
            <Box padding="6px 12px" textAlign="right" title={suppliersValue} whiteSpace="initial">
              {suppliersValue}
            </Box>
          )}
          <ValueContainer
            style={{ padding: suppliers?.length ? '6px 12px' : '12px', height: '100%' }}
            title={total ? formatAsCurrency(displayCurrency, total, 2) : null}
          >
            <Box textAlign="right">
              {!isEmpty(alternativeCurrencies) && (
                <AlternativeCurrencies>({alternativeCurrencies})</AlternativeCurrencies>
              )}
              {total ? formatAsCurrency(displayCurrency, total) : '-'}
            </Box>
          </ValueContainer>
        </Box>
      </Cell>
    );
  }
);

const EditStringCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId }) => ({
    estimateId: estimate.id,
    lineItemNameId: lineItemId,
  })),
  branch(({ isEditing }) => isEditing, renderComponent(EditableCell))
);

const EditDropdownCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId }) => ({
    estimateId: estimate.id,
    lineItemNameId: lineItemId,
  })),
  branch(({ isEditing }) => isEditing, renderComponent(EditableDropdownCell))
);

const EditCountryDropdownCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId }) => ({
    estimateId: estimate.id,
    lineItemNameId: lineItemId,
  })),
  branch(({ isEditing }) => isEditing, renderComponent(EditableCountryDropdownCell))
);

const StringCell = compose(
  getContext({ readOnly: PropTypes.bool }),
  withEditableness,
  branch(({ readOnly }) => !readOnly, EditStringCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    displayCurrency,
    value,
    edit,
    suppliers,
    supplierRequiredStatus,
    clientId,
    suppliersEnabled,
    estimate,
    errors = [],
    ...props
  }) => {
    return (
      <>
        <Cell {...props}>
          <Box width="100%" position="relative" display="flex" flexDirection="column" height="100%">
            <Errors errors={errors} />
            <Box textAlign="right">
              <ValueContainer onClick={edit}>{value || '-'}</ValueContainer>
            </Box>
          </Box>
        </Cell>
      </>
    );
  }
);

const DropdownCell = compose(
  getContext({ readOnly: PropTypes.bool }),
  withEditableness,
  branch(({ readOnly }) => !readOnly, EditDropdownCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    displayCurrency,
    value,
    edit,
    suppliers,
    supplierRequiredStatus,
    clientId,
    suppliersEnabled,
    estimate,
    ...props
  }) => {
    return (
      <Cell {...props}>
        <Box width="100%" display="flex" flexDirection="column" height="100%" onClick={edit}>
          <Box textAlign="right">
            <ValueContainer onClick={edit}>{value || '-'}</ValueContainer>
          </Box>
        </Box>
      </Cell>
    );
  }
);

const CountryCell = compose(
  getContext({ readOnly: PropTypes.bool }),
  withEditableness,
  branch(({ readOnly }) => !readOnly, EditCountryDropdownCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    displayCurrency,
    value,
    edit,
    suppliers,
    supplierRequiredStatus,
    clientId,
    suppliersEnabled,
    estimate,
    ...props
  }) => {
    return (
      <Cell {...props}>
        <Box width="100%" display="flex" flexDirection="column" height="100%" onClick={edit}>
          <Box textAlign="right">
            <ValueContainer onClick={edit}>{value || '-'}</ValueContainer>
          </Box>
        </Box>
      </Cell>
    );
  }
);

const EditNumberCell = compose(
  getContext({ estimate: PropTypes.object }),
  withProps(({ estimate, lineItemId }) => ({
    estimateId: estimate.id,
    lineItemNameId: lineItemId,
    type: 'number',
  })),
  branch(({ isEditing }) => isEditing, renderComponent(EditableCell))
);

const NumberCell = compose(
  getContext({ readOnly: PropTypes.bool }),
  withEditableness,
  branch(({ readOnly }) => !readOnly, EditNumberCell)
)(
  ({
    type,
    lineItemId,
    bidId,
    displayCurrency,
    value,
    edit,
    suppliers,
    supplierRequiredStatus,
    clientId,
    suppliersEnabled,
    errors = [],
    ...props
  }) => {
    return (
      <>
        <Cell {...props}>
          <Box width="100%" display="flex" flexDirection="column" height="100%">
            <Errors errors={errors} />
            <Box textAlign="right">
              <ValueContainer onClick={edit}>{value || '-'}</ValueContainer>
            </Box>
          </Box>
        </Cell>
      </>
    );
  }
);

const LineItemValueCell = compose(
  getContext({ estimate: PropTypes.object }),
  connect((state, { estimate, bidId, lineItemId, canBeDeleted, setCanBeDeleted }) => {
    const values = flow(get('bidValues'), find({ lineItemNameId: lineItemId, bidId }))(estimate);

    const lineItemNames =
      estimate?.lineItemGroups?.flatMap(({ lineItemNames, id }) =>
        lineItemNames.map(item => ({ ...item, costCategoryId: id }))
      ) ?? [];

    const value = get('value', values);
    const suppliers = (get('lineItemName.lineItemSuppliers', values) ?? []).map(
      ({ supplier: { id, name } }) => ({
        label: name,
        value: id,
      })
    );
    const lineItemName = find({ id: lineItemId }, lineItemNames);
    const type = get('type', lineItemName);
    const supplierRequiredStatus = get('supplierRequiredStatus', lineItemName);
    const clientSettings = getClientSettings(state);
    const suppliersEnabled = clientSettings[AccountSetting.Suppliers];
    const client = getClient(state);

    const props = {
      value,
      type,
      clientId: client.id,
      suppliersEnabled,
      estimateId: estimate.id,
    };

    if (estimate.requireLineItemChecks && lineItemName.mandatory) {
      setCanBeDeleted(false);

      //check if some of the related line items are populated
      const relatedLineItemsHasValues =
        lineItemName.relations && lineItemName.relations.length
          ? !!estimate?.bidValues
              ?.filter(
                item =>
                  item.bidId === bidId &&
                  lineItemName.relations.map(({ id }) => id).includes(item.lineItemNameId)
              )
              ?.some(item =>
                item.type === 'costSupplier'
                  ? item.value.amount || item?.lineItemName?.lineItemSuppliers?.length
                  : item.type === 'cost'
                  ? item.value.amount
                  : item.value
              )
          : false;

      props.errors =
        relatedLineItemsHasValues && type !== 'costSupplier' && !value
          ? ['Required']
          : type === 'costSupplier' &&
            (((value?.amount || suppliers.length) && !(value?.amount && suppliers.length)) ||
              relatedLineItemsHasValues)
          ? compact([
              (!value || value?.amount === null || value?.amount === 0) && 'Required cost',
              !suppliers.length && 'Required suppliers',
            ])
          : [];
    }

    // holy fuck what a mess
    if (suppliersEnabled) {
      props.suppliers = suppliers;
      props.supplierRequiredStatus = supplierRequiredStatus;
    }

    return props;
  }),
  withRecommendedBidSelected,
  branch(({ type }) => type === 'cost', renderComponent(LineItemCostValueCell)),
  branch(({ type }) => type === 'costSupplier', renderComponent(LineItemCostSuppliersCell)),
  branch(({ type }) => type === 'string', renderComponent(StringCell)),
  branch(({ type }) => type === 'dropdown', renderComponent(DropdownCell)),
  branch(({ type }) => type === 'number', renderComponent(NumberCell)),
  branch(({ type }) => type === 'country-dropdown', renderComponent(CountryCell))
)(({ value, ...props }) => {
  return (
    <Cell {...props}>
      <ValueContainer>{value || '-'}</ValueContainer>
    </Cell>
  );
});

const LineItemRow = ({
  bidIds,
  lineItemId,
  lastLineItemIdAdded,
  label,
  onDelete,
  forwardedRef,
  dragHandleProps,
  draggableProps,
  shouldAskDeleteConfirmation,
  className,
  pageId,
}) => {
  const [canBeDeleted, setCanBeDeleted] = useState(true);
  return (
    <Row
      className={className}
      onDelete={onDelete}
      forwardedRef={forwardedRef}
      draggableProps={draggableProps}
      dragHandleProps={dragHandleProps}
      withDeleteConfirmation={shouldAskDeleteConfirmation}
      scrollOnMount={lastLineItemIdAdded === lineItemId}
      canBeDeleted={canBeDeleted}
    >
      <RowTitle>{label}</RowTitle>
      {bidIds.map((bidId, i) => (
        <LineItemValueCell
          key={bidId}
          bidId={bidId}
          pageId={pageId}
          lineItemId={lineItemId}
          isEditing={lineItemId === lastLineItemIdAdded && i === 0}
          setCanBeDeleted={setCanBeDeleted}
          canBeDeleted={canBeDeleted}
        />
      ))}
    </Row>
  );
};

const mapStateToProps = (state, { lineItemId, estimate }) => ({
  shouldAskDeleteConfirmation: isLineItemLineEmpty(lineItemId)(state),
  lastLineItemIdAdded: getLastLineItemIdAdded(state),
  label: flow(
    get('lineItemGroups'),
    map('lineItemNames'),
    flatten,
    find({ id: lineItemId }),
    get('name')
  )(estimate),
  bidIds: flow(get('bids'), map('id'))(estimate),
});

const mapDispatchToProps = (dispatch, { estimate, costCategoryId, lineItemId, pageId }) => ({
  onDelete: () => dispatch(removeLineItem(estimate.id, costCategoryId, lineItemId, pageId)),
});

export default compose(
  withForwardingRef,
  getContext({ estimate: PropTypes.object, readOnly: PropTypes.bool }),
  connect(mapStateToProps, mapDispatchToProps)
)(LineItemRow);
