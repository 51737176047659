import React from 'react';
import { get, flow, map, flatten, uniq } from 'lodash/fp';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getCampaigns } from 'state/campaigns/selectors';
import { getEstimates } from 'state/estimates/selectors';
import { Message, ReportHeader } from '../components';
import Charts from './charts';
import Metrics from '../defaultMetrics';
import Table from './table';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const indexPropGet = (propKey, multipleName) =>
  flow(
    map('products'),
    map(values => (values.length ? values : [{ [propKey]: 'Not Set' }])),
    flatten,
    map(propKey),
    uniq,
    categories =>
      categories.length === 0
        ? 'Not Set' // no estimates
        : categories.length === 1
        ? categories[0]
        : `Multiple ${multipleName}`
  );

const Report = ({ estimates, campaigns, displayCurrency, filters }) => {
  const classes = useStyles();
  const showTable = Boolean(get('length', estimates) || get('length', campaigns));
  const title = 'Campaigns by Category Report';

  return (
    <div className={classes.container}>
      <ReportHeader title={title} />
      {!showTable && <Message>No data for the selected filters</Message>}
      {showTable && (
        <>
          <Metrics
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            filters={filters}
          />
          <Charts
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            indexPropGet={indexPropGet}
          />
          <Table
            estimates={estimates}
            campaigns={campaigns}
            indexPropGet={indexPropGet}
            displayCurrency={displayCurrency}
            title={title}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  estimates: getEstimates(state),
  campaigns: getCampaigns(state),
  displayCurrency: getDisplayCurrency(state),
});

export default connect(mapStateToProps)(Report);
