import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash/fp';
import { NavLink, withRouter } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Events, track } from 'components/analytics';
import { font } from 'shared/styles';
import { getUser } from 'state/authentication/selectors';
import ProfileMenu from './profileMenu';
import { getFilteredMenuItems, Divider } from './utils';
import MenuItemWithDropdown from './menuItemWithDropdown';

const isDemoSite = window.location.host.includes('demo');

const useStyles = makeStyles(theme => ({
  title: {
    width: !isDemoSite ? 75 : 170,
    margin: 'auto 0',
    marginRight: theme.spacing(5),
    textAlign: 'right',
    color: 'white',
    lineHeight: '22px',
    letterSpacing: -1.3,
    fontWeight: font.weights.bold,
    fontSize: font.sizes.veryLarge,
    textDecoration: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'white',
    fontFamily: 'Montserrat,sans-serif',
  },
  leftContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  navLink: {
    fontWeight: font.weights.thin,
    fontSize: font.sizes.veryLarge,
    color: 'white',
    margin: 'auto 20px',
    textDecoration: 'none',
    paddingBottom: 5,
    borderBottom: '2px solid transparent',
    transition: '0.6s ease-out',

    '&.active, &:hover': {
      color: 'white',
      borderBottomColor: 'white',
    },
  },
}));

export const menuItems = [
  {
    label: 'Campaigns',
    path: '/campaigns',
    id: 'campaigns',
    useSearch: true,
  },
  {
    label: 'Estimates',
    path: '/estimates',
    id: 'estimates',
    useSearch: true,
  },
  {
    label: 'Reports',
    path: '/reports',
    id: 'reports',
    useSearch: true,
    showChildren: false,
    children: [
      {
        id: 'campaigns-by-division',
        label: 'Campaigns by Division',
        path: '/campaigns-by-division',
      },
      {
        id: 'campaigns-by-category',
        label: 'Campaigns by Category',
        path: '/campaigns-by-category',
      },
      {
        id: 'cost-categories',
        label: 'Cost Categories',
        path: '/cost-categories',
      },
      {
        id: 'cumulative-spend',
        label: 'Cumulative Spend',
        path: '/cumulative-spend',
      },
      {
        id: 'lead-market',
        label: 'Lead Market',
        path: '/lead-market',
      },
      {
        id: 'top-line-custom',
        label: 'Top Line Custom',
        path: '/top-line-custom',
      },
      {
        id: 'deliverables',
        label: 'Additional Deliverables',
        path: '/deliverables',
      },
    ],
  },
  {
    label: 'Help',
    id: 'help',
    type: 'a',
    props: {
      href: 'https://help-app.murphycobb.com',
      target: '_blank',
      onClick: () => track(Events.HELP_CLICK),
    },
  },
];

const trackClick = path => track(Events.NAV_CLICK, { position: 'header', path });

const NavBar = ({ isAdmin, flags = [], isLoggedIn, location }) => {
  const classes = useStyles();
  return (
    <div className={classes.leftContainer}>
      <NavLink to="/" className={classes.title} onClick={() => trackClick('/')}>
        {!isDemoSite ? (
          'Control Room'
        ) : (
          <img src="/images/cr-logo.png" width="180px" height="50px" alt="control-room-logo" />
        )}
      </NavLink>
      <Divider style={{ height: !isDemoSite ? '40px' : '45px' }} />

      {map(({ id, path, label, admin, type, children, showChildren, props }) => {
        if (children && showChildren) {
          return (
            <MenuItemWithDropdown
              id={id}
              key={id}
              label={label}
              path={path}
              items={getFilteredMenuItems(children, { isAdmin, flags })}
            />
          );
        }
        return type === 'a' ? (
          <a key={id} className={classes.navLink} {...props}>
            {label}
          </a>
        ) : (
          <NavLink
            to={{ pathname: path, search: location.search, state: { prevPath: location.pathname } }}
            key={id}
            className={classes.navLink}
            data-test={`header:${id}Link`}
            onClick={() => trackClick(path)}
          >
            {label}
          </NavLink>
        );
      }, getFilteredMenuItems(menuItems, { isAdmin, flags }))}
    </div>
  );
};

const Header = ({ user, isLoading, logout, selectedCurrency, changeCurrency, location }) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <NavBar isAdmin={user.isAdmin} flags={user.flags} location={location} />
        <div className={classes.rightContainer}>
          <ProfileMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
});

export default withRouter(connect(mapStateToProps)(Header));
