import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { reject } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { getClientSettings, getDisplayCurrency, getFlags } from 'state/authentication/selectors';
import DataTable from '../dataTable';
import getColumns, { getDownloadColumns, getDetailedDownloadColumns } from './columns';
import EstimateCard from 'components/estimateCard';
import { TableHeader } from '../components';
import { csvDownload } from 'utils';
import { format as formatDate } from 'date-fns';
import { AccountSetting } from 'cr-core/constants';

const reportName = 'CostCategory';
const description =
  'Cost Categories report shows Estimates Volume and Spend by Cost Category. The Report offers clarity on the cost drivers of production. Know where the budget is spent, identify aberrations earlier and understand the effectiveness of initiatives more easily. Use the filters on the left to further customise this report.';

const useStyles = makeStyles(theme => ({
  estimateCard: {
    marginBottom: 0,
    borderRadius: 0,
  },
}));

const Table = ({ data = [], displayCurrency, flags, title, clientSettings }) => {
  const classes = useStyles();

  const columns = useMemo(() => getColumns(data, displayCurrency, clientSettings), [
    data,
    displayCurrency,
    clientSettings,
  ]);
  const [expandAll, setExpandAll] = useState(false);
  const toggleExpandAll = () => setExpandAll(!expandAll);
  const tableData = reject(({ value }) => value)(data);
  const costCategoriesExportEnabled = clientSettings[AccountSetting.CostCategoriesExport];
  const mergeLineItemsEnabled = clientSettings[AccountSetting.MergeHistoricalLineItems];
  const download = () => {
    const mappedData = data.map(item =>
      item.lineItemName === 'Sustainable Production Services Company' ||
      item.lineItemName === 'Sustainable Production Services Cost'
        ? { ...item, lineItemName: 'Sustainable Production Services Company and Cost' }
        : item.lineItemName === 'Post Production Company'
        ? { ...item, lineItemName: 'Editorial & Post Production Name and Costs' }
        : item.replacedBy
        ? { ...item, lineItemName: item.replacedByName }
        : item
    );

    const result = mappedData.reduce((a, v) => {
      const existingItem = a.find(
        item =>
          item.id === v.id &&
          item.lineItemId === v.lineItemId &&
          item.costCategoryId === v.costCategoryId
      );
      if (existingItem) {
        if (v.amount) {
          existingItem.amount = v.amount;
        }
        if (v.value) {
          existingItem.value = v.value;
        }
      } else {
        a.push(v);
      }
      return a;
    }, []);

    csvDownload(
      mergeLineItemsEnabled ? result : data,
      costCategoriesExportEnabled
        ? getDetailedDownloadColumns({ clientSettings })
        : getDownloadColumns({ clientSettings }),
      `Report_${reportName}_${formatDate(new Date(), 'yyyy-MM-dd')}`
    );
  };

  return (
    <>
      <TableHeader
        flags={flags}
        expandAll={expandAll}
        toggleExpandAll={toggleExpandAll}
        download={download}
        title={title}
      />
      <DataTable
        data={tableData}
        columns={columns}
        reportDescription={description}
        reportEventName={reportName}
        expandAll={expandAll}
        initialState={{
          groupBy: ['costCategory', 'lineItem'],
          sortBy: ['costCategory', 'lineItem'],
        }}
        SubComponent={({ row }) => (
          <EstimateCard estimate={row.original} target="_blank" className={classes.estimateCard} />
        )}
      />
    </>
  );
};

const mapStateToProps = state => ({
  displayCurrency: getDisplayCurrency(state),
  flags: getFlags(state),
  clientSettings: getClientSettings(state),
});

export default connect(mapStateToProps)(Table);
