import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import EstimateTypeChart from '../charts/estimateType';
import BudgetsChart from '../charts/budgets';
import EstimateStatusChart from '../charts/estimateStatus';
import { getDivisions, indexPropGetFnDivision, indexPropGetFnCountry } from './utils';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    alignItems: 'stretch',
  },
}));

const Charts = ({ estimates, campaigns, displayCurrency }) => {
  const classes = useStyles();
  const divisions = getDivisions(campaigns);
  const division = indexPropGetFnDivision(estimates);
  const indexPropGetFn = division.startsWith('Multiple')
    ? indexPropGetFnDivision
    : indexPropGetFnCountry;
  const breakdownLevel = division.startsWith('Multiple') ? 'Division' : 'Country';

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item lg={4} md={12}>
          <EstimateTypeChart estimates={estimates} displayCurrency={displayCurrency} />
        </Grid>
        <Grid item lg={4} md={12}>
          <BudgetsChart
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            title={`${breakdownLevel} Budget`}
            indexPropGetFn={indexPropGetFn}
          />
        </Grid>
        <Grid item lg={4} md={12}>
          <EstimateStatusChart
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            title={`Budget breakdown by ${breakdownLevel}`}
            indexPropGetFn={indexPropGetFn}
            bars={divisions.length}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Charts;
