import React from 'react';
import { Field } from 'redux-form';
import { map } from 'lodash/fp';
import styled from 'styled-components';
import {
  FieldContainer,
  InputContainer,
  Label,
  SubLabel,
  Select,
  createSelectOptionFromString,
} from 'components/forms';
import { ProductionComplexity, BudgetSources, BudgetCenter, YearField } from 'cr-core/constants';
import { CountrySelect, CountrySelectMulti } from 'components/countrySelect';
import { DateField } from 'components/datePicker';
import { colors, font } from 'shared/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export const Optional = styled(({ className }) => <span className={className}>Optional</span>)`
  color: ${colors.placeholder};
  font-style: italic;
  font-size: ${font.sizes.largish};
  display: inline-block;
  padding: 12px;
`;

export { default as Campaign } from './campaign.js';
export { default as EstimateType } from './estimateType.js';
export { default as Products } from './products.js';
export { default as ScriptTitles } from './scriptTitles.js';
export { default as Workspace } from './workspace.js';

export const PayingCountries = () => (
  <FieldContainer>
    <Label htmlFor="payingCountries">
      Paying Countries
      <SubLabel>Who is paying for this production?</SubLabel>
    </Label>
    <InputContainer>
      <Field
        name="payingCountries"
        id="select-paying-countries"
        component={CountrySelectMulti}
        placeholder="Select Countries / Regions..."
      />
    </InputContainer>
  </FieldContainer>
);

export const LeadMarket = () => {
  return (
    <FieldContainer>
      <Label htmlFor="leadMarket">
        Lead Market
        <SubLabel>Which country or region?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="leadMarket"
          id="select-lead-market"
          placeholder="Select Lead Market..."
          component={CountrySelect}
          isClearable={false}
          styles={{
            option: (provided, { data }) => ({
              ...provided,
              paddingLeft: data.region ? 20 : 10,
            }),
          }}
        />
      </InputContainer>
    </FieldContainer>
  );
};

export const AdditionalMarkets = () => (
  <FieldContainer>
    <Label htmlFor="additionalMarkets">
      Additional Markets
      <SubLabel>Which other countries or region are you targeting?</SubLabel>
    </Label>
    <InputContainer>
      <Field
        id="select-additional-market"
        name="additionalMarkets"
        component={CountrySelectMulti}
        placeholder="Select Countries / Regions..."
      />
    </InputContainer>
    <Optional />
  </FieldContainer>
);

const budgetSources = map(budget => ({ label: budget, value: budget }), BudgetSources);

export const BudgetSource = () => {
  return (
    <FieldContainer>
      <Label htmlFor="budgetSource">
        Budget Source<SubLabel>Where does your budget come from?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="budgetSource"
          component={({ input }) => (
            <Select
              id="select-budget-source"
              placeholder="Select Budget Source..."
              options={budgetSources}
              onChange={input.onChange}
              value={input.value}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};

export const BudgetCenterField = () => {
  return (
    <FieldContainer>
      <Label htmlFor="budgetCenter">
        Budget Center
        <SubLabel>Does this estimate belong to the Local or Regional budget?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="budgetCenter"
          component={({ input }) => (
            <Select
              placeholder="Select Budget Center..."
              options={Object.entries(BudgetCenter).map(([k, v]) => ({ value: k, label: v }))}
              onChange={input.onChange}
              value={input.value}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};
export const EstimateBundle = (checked = false) => {
  return (
    <FieldContainer>
      <Label htmlFor="bundle">
        Bundle<SubLabel>Is the project a bundle or not?</SubLabel>
      </Label>
      <FormControlLabel
        control={
          <Field
            name="bundle"
            component={({ input }) => (
              <Checkbox onChange={input.onChange} checked={input.value} color="primary" />
            )}
          />
        }
      />
    </FieldContainer>
  );
};

const budgetYears = map(
  year => ({ label: String(year), value: String(year) }),
  Array.from({ length: YearField.length }, (v, k) => k + YearField.startYear)
);
export const BudgetYear = () => {
  return (
    <FieldContainer>
      <Label htmlFor="budgetYear">
        Budget Year<SubLabel></SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="budgetYear"
          component={({ input }) => (
            <Select
              id="select-budget-year"
              placeholder="Select Budget Year..."
              options={budgetYears}
              onChange={input.onChange}
              value={input.value}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};

export const OriginalCount = () => (
  <FieldContainer>
    <Label htmlFor="numberOfOriginals">
      Number of Originals
      <SubLabel>How many original outputs are there?</SubLabel>
    </Label>
    <Field name="numberOfOriginals" component="input" type="number" min="0" className="textInput" />
  </FieldContainer>
);

export const EditCount = () => (
  <FieldContainer>
    <Label htmlFor="numberOfEdits">
      Number of Edits
      <SubLabel>How many edits will be created?</SubLabel>
    </Label>
    <Field name="numberOfEdits" component="input" type="number" min="0" className="textInput" />
  </FieldContainer>
);

export const EstimateName = () => (
  <FieldContainer>
    <Label htmlFor="estimateName">
      Estimate Name
      <SubLabel>What do you want to call this Estimate?</SubLabel>
    </Label>
    <InputContainer>
      <Field
        name="estimateName"
        type="text"
        component="input"
        label="Estimate Name"
        className="textInput"
        placeholder="Estimate name..."
      />
    </InputContainer>
  </FieldContainer>
);

export const EstimatePOCode = () => (
  <FieldContainer>
    <Label htmlFor="estimatePOCode">
      Purchase Order n.<SubLabel>Which is the purchase order number?</SubLabel>
    </Label>
    <InputContainer>
      <Field
        name="poCode"
        type="text"
        component="input"
        label="PO"
        className="textInput"
        placeholder="PO"
      />
    </InputContainer>
    <Optional />
  </FieldContainer>
);

export const ReportingDate = () => (
  <FieldContainer>
    <Label htmlFor="estimateName">
      Reporting Date
      {/*<SubLabel>Air Date / Launch Date</SubLabel>*/}
    </Label>
    <InputContainer>
      <Field name="date" component={DateField} />
    </InputContainer>
  </FieldContainer>
);

const complexityOptions = map(createSelectOptionFromString, ProductionComplexity);

export const Complexity = () => {
  return (
    <FieldContainer>
      <Label htmlFor="productionComplexity">
        Production Complexity<SubLabel>How complex is this production?</SubLabel>
      </Label>
      <InputContainer>
        <Field
          name="productionComplexity"
          component={({ input }) => (
            <Select
              id="select-production-complexity"
              placeholder="Select Production Complexity..."
              options={complexityOptions}
              onChange={input.onChange}
              value={input.value}
            />
          )}
        />
      </InputContainer>
    </FieldContainer>
  );
};
