import React from 'react';
import uuid from 'uuid/v4';
import qs from 'query-string';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withState, compose } from 'recompose';
import { flow, first, get, getOr, map, find } from 'lodash/fp';
import { Events, track, getEstimateData } from 'components/analytics';
import EstimateDetailForm from 'components/estimateEdit/estimateDetailForm';
import { createEstimate } from 'state/estimates/actions';
import { getUserWorkspaces } from 'state/workspaces/selectors';
import { getCampaigns } from 'state/campaigns/selectors';
import AppContainer from 'components/appContainerLegacy';

const mapStateToProps = state => {
  const hasMultipleWorkspaces = getUserWorkspaces(state).length > 1;
  const { campaignId } = qs.parse(state.router.location.search) || {};
  const campaign = flow(getCampaigns, find({ id: campaignId }))(state);
  const campaignOption = campaign
    ? {
        value: campaign.id,
        label: `${campaign.humanId} - ${campaign.name}`,
        workspaceId: campaign.workspaceId,
      }
    : null;

  return {
    hasMultipleWorkspaces,
    initialValues: {
      workspace: flow(
        getUserWorkspaces,
        workspaces =>
          campaignId && campaignOption
            ? find({ id: campaignOption.workspaceId }, workspaces)
            : first(workspaces),
        workspace => workspace && { value: workspace.id, label: workspace.name }
      )(state),
      campaign: campaignOption,
      poCode: getOr(null, 'poCode', campaign),
    },
  };
};

const mapDispatchToProps = (dispatch, { pageId }) => ({
  onSubmit: async formValues => {
    const products = flow(get('products'), map('value'))(formValues);
    const payingCountries = flow(get('payingCountries'), map('value'))(formValues);
    const additionalMarkets = flow(get('additionalMarkets'), map('value'))(formValues);
    const contentTypes = flow(get('typesOfDigital'), map('value'))(formValues);

    const estimate = {
      mediaType: get('mediaType.value', formValues),
      workspaceId: get('workspace.value', formValues),
      campaignId: get('campaign.value', formValues),
      leadMarket: get('leadMarket.value', formValues),
      name: get('estimateName', formValues),
      numberOfTVCs: get('numberOfOriginals', formValues),
      numberOfRATVs: get('numberOfEdits', formValues),
      externalMetadata: {
        scriptTitles: get('scriptTitles', formValues),
        contentTypes,
      },
      customData: {
        payingCountries,
        additionalMarkets,
        budgetSource: get('budgetSource.value', formValues),
        productionComplexity: get('productionComplexity.value', formValues),
      },
      date: get('date', formValues),
      poCode: get('poCode', formValues),
      budgetCenter: get('budgetCenter.value', formValues),
      budgetYear: get('budgetYear.value', formValues),
      bundle: get('bundle', formValues),
    };

    const newEstimate = await dispatch(createEstimate(estimate, products, pageId));

    track(Events.ESTIMATE_CREATE_SUCCESS, getEstimateData(newEstimate));
  },
});

const CreateNewEstimate = compose(
  withState('pageId', 'setPageId', () => uuid()),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'createEstimate', enableReinitialize: true })
)(EstimateDetailForm);

export default () => (
  <AppContainer>
    <CreateNewEstimate />
  </AppContainer>
);
