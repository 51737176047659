import { Box, Tooltip } from '@material-ui/core';
import isEqual from 'react-fast-compare';
import { CreatableSelect } from 'components/forms';
import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { Waypoint } from 'react-waypoint';
import EstimateService from 'services/EstimateService';
import { Supplier } from 'services/SupplierService';
import { debounce } from 'lodash/fp';
import { displayNotification } from 'state/notifications/actions';
import { Notification } from 'types/notification';
import { connect } from 'react-redux';
import BidService from 'services/BidService';
import SaveCancelPopup from 'components/forms/saveCancelPopup';
import { Add } from '@material-ui/icons';
import { updateLineItemName } from 'state/bids/actions';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { getLineItemById } from 'state/bids/selectors';

interface Props {
  open: boolean;
  accountId: string;
  lineItemNameId: string;
  bidId: string;
  initialValue?: Option[];
  innerRef: MutableRefObject<Instance>;
  estimateId: string;
  displayCurrency: string;
  onClose: () => void;
  lineItemById: any;
  updateLineItemName: (value: any) => void;
  showNotification: (value: Notification) => void;
}

interface Instance {
  save: () => void;
}

interface Option {
  label: string;
  value: string;
}

const MenuList = ({
  children,
  selectProps,
}: React.PropsWithChildren<{
  selectProps: { hasNextPage: boolean; fetchNextPage: () => void };
}>) => {
  const { fetchNextPage, hasNextPage } = selectProps;

  return (
    <Box maxHeight={300} overflow="auto">
      {children}
      {hasNextPage && <Waypoint onEnter={fetchNextPage} />}
    </Box>
  );
};

const SuppliersSelectBase = ({
  accountId,
  value,
  showNotification,
  onChange,
  onCreate,
}: {
  accountId: string;
  value: Option[];
  estimateId: string;
  showNotification: (value: Notification) => void;
  onCreate: (option: Option) => void;
  onChange: (options: Option[]) => void;
}) => {
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['estimates', 'account', accountId, 'suppliers', search],
    ({ pageParam = 1 }) =>
      EstimateService.getSuppliers({ accountId, page: pageParam, q: search }).then(
        ({ data }) => data
      ),
    { getNextPageParam: lastPage => lastPage.nextPage ?? undefined }
  );

  const { mutate } = useMutation(
    ({ name }: { name: string }) =>
      EstimateService.createSupplier({ accountId, name }).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['estimates', 'account', accountId, 'suppliers']);
      },
    }
  );

  const suppliers = useMemo(
    () =>
      (data?.pages ?? [])
        .reduce((acc, cur) => acc.concat(cur.data), [] as Supplier[])
        .map(item => ({ value: item.id, label: item.name })),
    [data]
  );

  const handleInputChange = useCallback(
    debounce(300, (value: string, { action }: any) => {
      if (action !== 'input-change') {
        setSearch('');
        return;
      }

      setSearch(value);
    }),
    []
  );

  const handleCreate = (value: string) => {
    mutate(
      { name: value },
      {
        onSuccess: ({ id, name }) => {
          showNotification({ type: 'success', message: 'Supplier created successfully.' });

          const option = { value: id, label: name };
          onCreate(option);
        },
        onError: () => showNotification({ type: 'error', message: 'Failed to create supplier.' }),
      }
    );
  };

  return (
    <CreatableSelect
      isLoading={isFetchingNextPage}
      defaultOptions
      isMulti
      createOptionPosition="first"
      options={suppliers}
      formatCreateLabel={(value: string) => (
        <Box display="flex" alignItems="center">
          <Add />{' '}
          <Box component="span" ml={1}>
            Create "{value}"
          </Box>
        </Box>
      )}
      onInputChange={handleInputChange}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      styles={{ control: (provided: any) => ({ ...provided, height: 'auto !important' }) }}
      onCreateOption={handleCreate}
      onChange={onChange}
      value={value}
      components={{
        MenuList,
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  showNotification: (value: Notification) => {
    dispatch(displayNotification(value));
  },
});

const SuppliersSelect = connect(null, mapDispatchToProps)(SuppliersSelectBase);

const LineItemSuppliers = ({
  accountId,
  bidId,
  initialValue = [],
  lineItemNameId,
  innerRef,
  estimateId,
  displayCurrency,
  updateLineItemName,
  showNotification,
  lineItemById,
}: Props) => {
  const [selected, setSelected] = useState<Option[]>(initialValue);

  const isDirty = useMemo(
    () =>
      !isEqual(
        initialValue.map(({ value }) => value).sort(),
        selected.map(({ value }) => value).sort()
      ),
    [initialValue, selected]
  );

  const { mutate: addSuppliers, isLoading } = useMutation(
    () =>
      BidService.addLineItemSuppliers({
        bidId,
        lineItemNameId,
        supplierIds: selected.map(({ value }) => value),
      }).then(({ data }) => data),
    {
      onSuccess: data => {
        showNotification({ type: 'success', message: 'Suppliers updated successfully' });

        const lineItemName = { lineItemSuppliers: data.map(supplier => ({ supplier })) };

        updateLineItemName({
          bidId,
          lineItemNameId,
          data: lineItemName,
          meta: {
            type: 'costSupplier',
            value: { amount: null, currency: displayCurrency },
          },
          mandatory: lineItemById.mandatory,
          lineItemName: lineItemById,
        });
      },

      onError: () => {
        showNotification({ type: 'error', message: 'Failed to update suppliers' });
      },
    }
  );

  const handleCreate = (option: Option) => {
    setSelected(old => [...old, option]);
  };

  const handleChange = (options: Option[]) => {
    setSelected(options);
  };

  const handleSave = () => {
    if (isLoading || !isDirty) {
      return;
    }

    addSuppliers();
  };

  useImperativeHandle(innerRef, () => ({ save: handleSave }));

  return (
    <Box position="relative">
      <Tooltip open placement="right" arrow title="Type to create new supplier">
        <SuppliersSelect
          onCreate={handleCreate}
          onChange={handleChange}
          estimateId={estimateId}
          value={selected}
          accountId={accountId}
        />
      </Tooltip>
      {isDirty && (
        <SaveCancelPopup
          onSave={handleSave}
          onCancel={() => setSelected(initialValue)}
          // @ts-ignore
          className="editableCell-saveCancelPopup"
        />
      )}
    </Box>
  );
};

const ConnectedComponent = connect(
  (state, { lineItemNameId }) => ({
    displayCurrency: getDisplayCurrency(state),
    lineItemById: getLineItemById(lineItemNameId)(state),
  }),
  (dispatch: Function, { estimateId }: any) => ({
    updateLineItemName: ({ bidId, lineItemNameId, data, meta, mandatory, lineItemName }: any) =>
      dispatch(
        updateLineItemName({
          estimateId,
          bidId,
          lineItemNameId,
          data,
          meta,
          mandatory,
          lineItemName,
        })
      ),
    showNotification: (value: Notification) => dispatch(displayNotification(value)),
  })
)(LineItemSuppliers);

export default forwardRef((props, ref) => <ConnectedComponent {...props} innerRef={ref} />);
