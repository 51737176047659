import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import AppContainer from 'components/listPageContainer';
import { getCampaignsPagination } from 'state/campaigns/selectors';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Hidden } from '@material-ui/core';
import { track, getFiltersChangedData, Events } from 'components/analytics';
import CreateCampaignButton from 'components/buttons/campaignCreate';
import CampaignsList from 'components/campaigns';
import CampaignDetails from 'components/campaigns/campaignDetails';
import Filters, {
  defaultFilters,
  stringifyFilters,
  parseQueryFilters,
} from 'components/filtersSidebar';
import { openSidePanel, closeSidePanel } from 'state/ui/actions';
import { getSidePanelData } from 'state/ui/selectors';

const sidePanelViews = {
  campaignDetails: 'CAMPAIGN_DETAILS',
};

const initiallyDisplayedFilters = [
  'search',
  'workspaceId',
  'budgetYear',
  'reportingYear',
  'assignedToMe',
  'categoryBrand',
  'estimateStatus',
  'mediaType',
  'payingCountries',
  'excludeCancelledEstimates',
];

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    maxWidth: 310,
  },
  listContainer: {
    padding: theme.spacing(2, 3, 0),
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flexEnd',
  },
  pagination: {
    marginRight: theme.spacing(2),
  },
}));

const CampaignsPage = ({
  pagination,
  sidePanelData,
  openSidePanel,
  closeSidePanel,
  history,
  location,
}) => {
  const classes = useStyles();
  const [pageId] = useState(uuid());
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...parseQueryFilters(location.search),
  });

  const onChangeFilters = (values, prevValues) => {
    setFilters(values);
    track(Events.FILTERS_CHANGED, {
      page: 'campaigns',
      ...getFiltersChangedData(values, prevValues),
    });
  };

  let sidePanelContent;
  if (sidePanelData.view === sidePanelViews.campaignDetails) {
    sidePanelContent = <CampaignDetails pageId={pageId} />;
  } else {
    sidePanelContent = null;
  }

  const onCloseSidePanel = () =>
    history.push({
      path: location.path,
      search: stringifyFilters(filters, defaultFilters),
    });

  return (
    <AppContainer
      sidePanelContent={sidePanelContent}
      onCloseSidePanel={onCloseSidePanel}
      pageId={pageId}
    >
      <div className={classes.filtersContainer}>
        <Filters
          pageId={pageId}
          onChange={onChangeFilters}
          filters={filters}
          initiallyDisplayedFilters={initiallyDisplayedFilters}
        />
      </div>
      <div className={classes.listContainer}>
        <div className={classes.header}>
          <div className={classes.headerRight}>
            <Typography variant="h4">Campaigns</Typography>
          </div>
          <div className={classes.headerRight}>
            {Boolean(pagination.totalResults) && (
              <div className={classes.pagination}>
                <Hidden smDown>{pagination.totalResults} campaigns</Hidden>
              </div>
            )}
            <CreateCampaignButton className={classes.button} />
          </div>
        </div>
        <CampaignsList pageId={pageId} filters={filters} />
      </div>
    </AppContainer>
  );
};

const mapStateToProps = (state, { location }) => {
  return {
    pagination: getCampaignsPagination(state),
    sidePanelData: getSidePanelData(state),
  };
};

const mapDispatchToProps = dispatch => ({
  openSidePanel: data => dispatch(openSidePanel(data)),
  closeSidePanel: () => dispatch(closeSidePanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPage);
