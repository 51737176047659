import { getOr, uniq, sumBy, uniqBy, pick } from 'lodash/fp';
import { CurrencyValueCell, FooterValueCell } from '../components';
import { aggregateSum } from '../utils';
import * as donwloadColumnsUtils from '../utils.downloadColumns';
import { estimateName, estimateBundle, estimateSuppliers } from '../utils.columns';
import { AccountSetting } from 'cr-core/constants';
import { compact } from 'lodash/fp';

const download = (data, displayCurrency, clientSettings) => {
  const columns = compact([
    {
      id: 'costCategory',
      Header: 'Cost Category',
      description: 'A group of costs. Costs, relevant to each other, are presented in categories.',
      maxWidth: 230,
      accessor: 'costCategoryName',
      PivotValue: ({ value }) => value,
      Footer: () => 'Total',
    },
    {
      id: 'lineItem',
      Header: 'Line Item',
      description:
        'A specific description for a cost. Line items are saved by cost categories & bids.',
      accessor: 'lineItemName',
      aggregate: (values = []) => getOr(0, 'length', uniq(values)),
      PivotValue: ({ value }) => value,
      Aggregated: ({ value }) => value,
    },
    { ...estimateName(), Footer: () => '' },
    clientSettings[AccountSetting.Suppliers] && estimateSuppliers(),
    {
      id: 'average',
      Header: 'Average',
      description:
        'The average spend for a cost category and a line item, based on their latest estimated spend.',
      accessor: pick(['id', 'amount']),
      aggregate: values => {
        const count = uniqBy('id', values).length;
        const sum = sumBy('amount', values);
        return sum / count;
      },
      maxWidth: 130,
      Cell: props =>
        CurrencyValueCell(displayCurrency)({
          ...props,
          cell: { ...props.cell, value: props.value.amount },
        }),
      Aggregated: CurrencyValueCell(displayCurrency),
      align: 'right',
    },
    {
      id: 'total',
      Header: 'Estimated Spend',
      description:
        ' The sum of the spend for a line item, based on the latest estimate." to this "Estimated Spend: The sum of the spend for a cost category and a line item respectively, based on the latest estimate.',
      accessor: 'amount',
      aggregate: aggregateSum,
      maxWidth: 130,
      Cell: CurrencyValueCell(displayCurrency),
      Aggregated: CurrencyValueCell(displayCurrency),
      Footer: ({ data = [] }) => FooterValueCell(sumBy('amount', data), displayCurrency),
      align: 'right',
    },
  ]);
  clientSettings[AccountSetting.EstimateBundle] && columns.splice(3, 0, estimateBundle());

  return columns;
};

export default download;

// these are supposed to be used temporarily until the same things are not
// fixed across all reports
const estimateCampaignBudget = {
  label: 'Budget',
  value: value => getOr(0, 'campaign.budget', value)?.toFixed(2) ?? 0,
};

export const latestEstimate = {
  label: 'Estimated Spend',
  value: value => getOr(0, 'recommendedBidTotal', value)?.toFixed(2) ?? 0,
};

export const estimateActual = {
  label: 'Actualised Spend',
  value: value => getOr(0, 'actualisedTotal', value)?.toFixed(2) ?? 0,
};

export const lineItemCost = {
  label: 'Line Item Cost',
  value: value => {
    const amount = getOr('', 'amount', value);
    return amount === '' ? '' : amount?.toFixed(2) ?? '';
  },
};

export const wpp = {
  label: 'WPP/NON WPP',
  value: value => {
    const name = value.lineItemName;
    const lineItemName = value.lineItems.find(item => item.lineItemName === name);
    if (lineItemName && lineItemName.hasOwnProperty('suppliers')) {
      const supplierWpp =
        lineItemName.suppliers.length && lineItemName.suppliers[0].supplier
          ? lineItemName.suppliers[0].supplier.wpp
          : '';
      return supplierWpp;
    }
    return '';
  },
};

export const getDetailedDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    donwloadColumnsUtils.estimateBudgetYear,
    donwloadColumnsUtils.estimateApprovedAt,
    donwloadColumnsUtils.estimateDivision,
    donwloadColumnsUtils.estimateBudgetCenter,
    donwloadColumnsUtils.workspaceName,
    donwloadColumnsUtils.estimateType,
    donwloadColumnsUtils.estimateProductCategory,
    donwloadColumnsUtils.costCategoryName,
    donwloadColumnsUtils.lineItemName,
    clientSettings[AccountSetting.Suppliers] && wpp,
    donwloadColumnsUtils.lineItemValue(clientSettings),
    lineItemCost,
    donwloadColumnsUtils.estimateCampaignNumber,
    donwloadColumnsUtils.estimateCampaignName,
    estimateCampaignBudget,
    donwloadColumnsUtils.estimateNumber,
    donwloadColumnsUtils.estimateName,
    latestEstimate,
    estimateActual,
    donwloadColumnsUtils.estimateStatus,
    donwloadColumnsUtils.estimateBudgetSource,
    donwloadColumnsUtils.estimatePayingCountry,
    donwloadColumnsUtils.estimateLeadMarket,
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
    donwloadColumnsUtils.estimateCreator,
    donwloadColumnsUtils.estimateLink,
  ]);

export const getDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    donwloadColumnsUtils.costCategoryName,
    donwloadColumnsUtils.lineItemName,
    clientSettings[AccountSetting.Suppliers] && wpp,
    donwloadColumnsUtils.lineItemCost,
    donwloadColumnsUtils.lineItemValue(clientSettings),
    ...donwloadColumnsUtils.estimateInfo(clientSettings),
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
  ]);
