import {
  keyBy,
  sortBy,
  map,
  flow,
  get,
  identity,
  merge,
  fromPairs,
  mapValues,
  flatten,
} from 'lodash/fp';
import WorldCountries from 'world-countries';
import {
  africaEurasia,
  asiaPacific,
  europe,
  latinAmerica,
  northAmerica,
  AFRICA_EURASIA,
  ASIA_PACIFIC,
  EUROPE,
  LATIN_AMERICA,
  NORTH_AMERICA,
  GLOBAL,
  worldCountries,
} from 'cr-core/colgateRegions';

const missingCountriesMap = {
  'Czech Republic': {
    name: 'Czech Republic',
    flag: require('world-countries/data/cze.svg'),
    countryCode: 'CZE',
  },
  CARICOM: {
    name: 'CARICOM',
    flag: require('icons/caricom-flag.svg'),
    countryCode: 'CARICOM',
  },
  'North Macedonia': {
    name: 'North Macedonia',
    flag: require('world-countries/data/mkd.svg'),
    countryCode: 'MKD',
  },
  GCC: {
    name: 'Gulf Cooperation Council (GCC)',
    flag: require('icons/gcc-flag.svg'),
    countryCode: 'GCC',
  },
  Eurasia: {
    name: 'Eurasia',
    flag: require('icons/no-flag.svg'),
    countryCode: 'EURASIA',
  },
  NAMET: {
    name: 'NAMET',
    flag: require('icons/no-flag.svg'),
    countryCode: 'NAMET',
  },
  'East West Africa': {
    name: 'East West Africa',
    flag: require('icons/no-flag.svg'),
    countryCode: 'EWA',
  },
  CACE: {
    name: 'Central Asia (CACE)',
    flag: require('icons/no-flag.svg'),
    countryCode: 'CACE',
  },
};

const allCountriesByName = flow(
  map(({ name, cca3: countryCode }) => {
    return {
      name: name.common,
      flag: require(`world-countries/data/${countryCode.toLowerCase()}.svg`),
      countryCode,
    };
  }),
  merge(missingCountriesMap),
  keyBy('name')
)(WorldCountries);

const decorateWithInfo = flow(
  sortBy(identity),
  map(country => get(country, allCountriesByName))
);

const decoratedRegions = [
  { name: 'Africa & Eurasia', code: AFRICA_EURASIA, countries: decorateWithInfo(africaEurasia) },
  { name: 'Asia Pacific', code: ASIA_PACIFIC, countries: decorateWithInfo(asiaPacific) },
  { name: 'Europe', code: EUROPE, countries: decorateWithInfo(europe) },
  { name: 'Latin America', code: LATIN_AMERICA, countries: decorateWithInfo(latinAmerica) },
  { name: 'North America', code: NORTH_AMERICA, countries: decorateWithInfo(northAmerica) },
  { name: 'Global', code: GLOBAL },
];

export const decoratedCountries = decorateWithInfo(worldCountries);

export default decoratedRegions;

export const regionsByCode = flow(keyBy('code'), mapValues('name'))(decoratedRegions);

export const countries = flow(map('countries'), flatten, sortBy('name'))(decoratedRegions);

export const countriesByName = flow(keyBy('name'), mapValues('name'))(countries);

const createRegionCountryPairs = regionName => map(countryName => [countryName, regionName]);

export const countryToRegion = fromPairs([
  ...createRegionCountryPairs(AFRICA_EURASIA)(africaEurasia),
  ...createRegionCountryPairs(ASIA_PACIFIC)(asiaPacific),
  ...createRegionCountryPairs(EUROPE)(europe),
  ...createRegionCountryPairs(LATIN_AMERICA)(latinAmerica),
  ...createRegionCountryPairs(NORTH_AMERICA)(northAmerica),
  ['Africa & Eurasia', AFRICA_EURASIA],
  ['Asia Pacific', ASIA_PACIFIC],
  ['Europe', EUROPE],
  ['Latin America', LATIN_AMERICA],
  ['North America', NORTH_AMERICA],
  ['Global', GLOBAL],
]);
