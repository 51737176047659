import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { map, get, size } from 'lodash/fp';
import { EstimateStatuses, MediaTypesWithDeliverables } from 'cr-core/constants';
import { Events, track } from 'components/analytics';
import { getDisplayCurrency } from 'state/authentication/selectors';
import { fetchWatchers } from 'state/estimateWatchers/actions';
import { fetch } from 'state/fetchMiddleware';
import { getUserId } from '../authentication/selectors';
import { getEstimateById } from './selectors';

export const ESTIMATE_FETCH_REQUEST = 'estimate/FETCH_REQUEST';
export const ESTIMATE_FETCH_SUCCESS = 'estimate/FETCH_SUCCESS';
export const ESTIMATE_FETCH_ERROR = 'estimate/ESTIMATE_FETCH_ERROR';

export const ESTIMATE_CREATE_REQUEST = 'estimate/CREATE_REQUEST';
export const ESTIMATE_CREATE_SUCCESS = 'estimate/CREATE_SUCCESS';
export const ESTIMATE_CREATE_FAILED = 'estimate/CREATE_FAILED';

export const ESTIMATE_UPDATE_REQUEST = 'estimate/ESTIMATE_UPDATE_REQUEST';
export const ESTIMATE_UPDATE_SUCCESS = 'estimate/ESTIMATE_UPDATE_SUCCESS';

export const ESTIMATE_UPDATE_FIELD_SUCCESS = 'estimate/ESTIMATE_UPDATE_FIELD_SUCCESS';
export const ESTIMATE_UPDATE_NOTES_REQUEST = 'estimate/ESTIMATE_UPDATE_NOTES_REQUEST';

export const SUBMIT_ESTIMATE_REQUEST = 'estimate/SUBMIT_ESTIMATE_REQUEST';
export const SUBMIT_ESTIMATE_SUCCESS = 'estimate/SUBMIT_ESTIMATE_SUCCESS';
export const SUBMIT_ESTIMATE_ERROR = 'estimate/SUBMIT_ESTIMATE_ERROR';
export const SUBMIT_ESTIMATE_RESET = 'estimate/SUBMIT_ESTIMATE_RESET';

export const RESUBMIT_ESTIMATE_REQUEST = 'estimate/RESUBMIT_ESTIMATE_REQUEST';
export const RESUBMIT_ESTIMATE_SUCCESS = 'estimate/RESUBMIT_ESTIMATE_SUCCESS';
export const RESUBMIT_ESTIMATE_ERROR = 'estimate/RESUBMIT_ESTIMATE_ERROR';
export const RESUBMIT_ESTIMATE_RESET = 'estimate/RESUBMIT_ESTIMATE_RESET';

export const REMOVE_ESTIMATE_FILE_REQUEST = 'estimate/REMOVE_ESTIMATE_FILE_REQUEST';
export const REMOVE_ESTIMATE_FILE_SUCCESS = 'estimate/REMOVE_ESTIMATE_FILE_SUCCESS';
export const UPDATE_LINE_ITEM_NAME = 'UPDATE_LINE_ITEM_NAME';

export const ESTIMATE_DELETE_REQUEST = 'estimate/ESTIMATE_DELETE_REQUEST';
export const ESTIMATE_DELETE_SUCCESS = 'estimate/ESTIMATE_DELETE_SUCCESS';

export const CLOSE_ACTUALISATION_ESTIMATE_REQUEST = 'estimate/CLOSE_ACTUALISATION_ESTIMATE_REQUEST';
export const CLOSE_ACTUALISATION_ESTIMATE_SUCCESS = 'estimate/CLOSE_ACTUALISATION_ESTIMATE_SUCCESS';
export const CLOSE_ACTUALISATION_ESTIMATE_ERROR = 'estimate/CLOSE_ACTUALISATION_ESTIMATE_ERROR';

export const fetchEstimate = (id, requestId) => async (dispatch, getState) => {
  const displayCurrency = getDisplayCurrency(getState());
  dispatch({ type: ESTIMATE_FETCH_REQUEST, id, requestId });
  const t = Date.now();
  try {
    const { data: estimate } = await dispatch(
      fetch(`/estimates/${id}`, { params: { displayCurrency } })
    );
    track(Events.PERFORMANCE, { metric: 'fetch_estimate', t: Date.now() - t });
    const currentUserId = getUserId(getState());
    dispatch({ type: ESTIMATE_FETCH_SUCCESS, estimate, id, currentUserId, requestId });
  } catch (e) {
    dispatch({ type: ESTIMATE_FETCH_ERROR, id, error: e });
  }
};

export const createEstimate = (estimateData, products, requestId) => async dispatch => {
  dispatch({ type: ESTIMATE_CREATE_REQUEST, requestId });

  const { data: estimate } = await dispatch(
    fetch('/estimates', { method: 'POST', data: estimateData })
  );

  dispatch({ type: ESTIMATE_CREATE_SUCCESS, id: estimate.id, estimate, requestId });
  if (size(products)) {
    estimate.products = await dispatch(saveProducts(estimate.id, products));
  }

  if (MediaTypesWithDeliverables.includes(estimate.mediaType)) {
    dispatch(push(`/estimates/${estimate.id}/deliverables/edit`));
  } else {
    dispatch(push(`/estimates/${estimate.id}/bids`));
  }

  return estimate;
};

const saveProducts = (estimateId, productIds) => async dispatch => {
  const { data: products } = await dispatch(
    fetch(`/estimates/${estimateId}/products`, { method: 'PUT', data: productIds })
  );

  dispatch({
    type: ESTIMATE_UPDATE_FIELD_SUCCESS,
    estimateId,
    field: 'products',
    value: products,
  });

  return products;
};

export const updateEstimate =
  (
    estimateId,
    {
      mediaType,
      estimateName,
      campaign,
      numberOfOriginals: numberOfTVCs,
      numberOfEdits: numberOfRATVs,
      typesOfDigital,
      scriptTitles,
      products: selectedProducts,
      payingCountries: selectedPayingCountries,
      recommendedBid,
      leadMarket: selectedLeadMarket,
      additionalMarkets: selectedAdditionalMarkets,
      budgetSource: selectedBudgetSource,
      productionComplexity: selectedProductionComplexity,
      workspace: selectedWorkspace,
      budgetCenter: selectedBudgetCenter,
      budgetYear: selectedBudgetYear,
      date,
      edits,
      originalsType,
      additionalDeliverables,
      clone,
      poCode,
      acceptCovid19RelatedIssues,
      deliverableOriginalsType,
      bundle,
    },
    requestId,
    { invalidateApprovals = false } = {}
  ) =>
  async dispatch => {
    dispatch({
      type: ESTIMATE_UPDATE_REQUEST,
      id: estimateId,
      requestId,
    });
    const payingCountries = selectedPayingCountries && map('value', selectedPayingCountries);
    const additionalMarkets = selectedAdditionalMarkets && map('value', selectedAdditionalMarkets);
    const products = selectedProducts && map('value', selectedProducts);
    const leadMarket = selectedLeadMarket && selectedLeadMarket.value;
    const budgetSource = selectedBudgetSource && selectedBudgetSource.value;
    const productionComplexity = selectedProductionComplexity && selectedProductionComplexity.value;
    const workspaceId = selectedWorkspace && selectedWorkspace.value;
    const budgetCenter = selectedBudgetCenter && selectedBudgetCenter.value;
    const budgetYear = selectedBudgetYear && selectedBudgetYear.value;

    if (numberOfTVCs === '') {
      numberOfTVCs = undefined;
    }
    if (numberOfRATVs === '') {
      numberOfRATVs = undefined;
    }

    if (get('value', mediaType) && !MediaTypesWithDeliverables.includes(get('value', mediaType))) {
      numberOfTVCs = 0;
      numberOfRATVs = 0;
      clone = false;
      additionalDeliverables = {};
    }

    const { data: estimate } = await dispatch(
      fetch(`/estimates/${estimateId}`, {
        method: 'PATCH',
        data: {
          mediaType: get('value', mediaType),
          name: estimateName,
          campaignId: get('value', campaign),
          numberOfTVCs,
          numberOfRATVs,
          externalMetadata: { scriptTitles },
          customData: {
            payingCountries,
            budgetSource,
            productionComplexity,
            additionalMarkets,
            acceptCovid19RelatedIssues,
          },
          recommendedBid,
          leadMarket,
          workspaceId,
          date,
          products,
          edits,
          originalsType,
          additionalDeliverables,
          budgetCenter,
          budgetYear,
          clone,
          poCode,
          invalidateApprovals,
          deliverableOriginalsType,
          bundle,
        },
      })
    );
    dispatch({
      type: ESTIMATE_UPDATE_SUCCESS,
      id: estimate.id,
      estimate,
      requestId,
    });

    await fetchWatchers(estimateId)(dispatch);
    return estimate;
  };

export const updateEstimateNotes = (estimateId, patch) => async dispatch => {
  dispatch({ type: ESTIMATE_UPDATE_NOTES_REQUEST, estimateId, patch });
  const { data: estimate } = await dispatch(
    fetch(`/estimates/${estimateId}`, {
      method: 'PATCH',
      data: patch,
    })
  );
  dispatch({ type: ESTIMATE_UPDATE_SUCCESS, id: estimate.id, estimate });
  fetchWatchers(estimateId)(dispatch);
};

export const submitEstimate = (estimateId, message, requestId) => async dispatch => {
  dispatch({ type: SUBMIT_ESTIMATE_REQUEST, estimateId, requestId });

  try {
    const { data: estimate } = await dispatch(
      fetch(`/estimates/${estimateId}/status`, {
        method: 'PUT',
        data: { status: EstimateStatuses.PENDING_APPROVAL, message },
      })
    );

    dispatch({
      type: SUBMIT_ESTIMATE_SUCCESS,
      estimateId,
      estimate,
      message,
      requestId,
    });

    dispatch(reset('submitForApproval'));
    fetchWatchers(estimateId)(dispatch);
  } catch (error) {
    dispatch({ type: SUBMIT_ESTIMATE_ERROR, estimateId, error });
  }
};

export const resubmitEstimate = (estimateId, message, requestId) => async dispatch => {
  dispatch({ type: RESUBMIT_ESTIMATE_REQUEST, estimateId, requestId });

  try {
    const { data: estimate } = await dispatch(
      fetch(`/estimates/${estimateId}/resubmit`, {
        method: 'POST',
      })
    );

    dispatch({
      type: RESUBMIT_ESTIMATE_SUCCESS,
      estimateId,
      estimate,
      message,
      requestId,
    });

    dispatch(reset('submitForApproval'));
    fetchWatchers(estimateId)(dispatch);
  } catch (error) {
    dispatch({ type: RESUBMIT_ESTIMATE_ERROR, estimateId, error });
  }
};

export const resetSubmitEstimate = () => async dispatch => {
  dispatch({ type: SUBMIT_ESTIMATE_RESET });
};

export const removeFileFromEstimate = (fileId, estimateId) => async dispatch => {
  dispatch({ type: REMOVE_ESTIMATE_FILE_REQUEST, fileId, estimateId });
  await dispatch(
    fetch(`/estimates/${estimateId}/files/${fileId}`, {
      method: 'DELETE',
    })
  );
  dispatch({ type: REMOVE_ESTIMATE_FILE_SUCCESS, fileId, estimateId });
  fetchWatchers(estimateId)(dispatch);
};

export const deleteEstimate = estimateId => async dispatch => {
  dispatch({ type: ESTIMATE_DELETE_REQUEST, estimateId });
  await dispatch(
    fetch(`/estimates/${estimateId}`, {
      method: 'DELETE',
    })
  );
  dispatch({ type: ESTIMATE_DELETE_SUCCESS, estimateId });
};

export const fetchEstimateStatus = (estimateId, requestId) => async (dispatch, getState) => {
  const estimate = getEstimateById(estimateId)(getState());
  // TODO: fix this properly, apparently estimate can be undefined for some reason, find out why
  if (estimate && estimate.status !== EstimateStatuses.DRAFT) {
    await dispatch(fetchEstimate(estimateId, requestId)); // might be too heavy?
    // Might want to have a separate fetch for updating the status?
    // await dispatch(fetchEstimateStatus(estimateId));
  }
};

export const closeActualisation = (estimateId, requestId) => async dispatch => {
  dispatch({ type: CLOSE_ACTUALISATION_ESTIMATE_REQUEST, estimateId, requestId });
  try {
    const { data: estimate } = await dispatch(
      fetch(`/estimates/${estimateId}/status`, {
        method: 'PUT',
        data: { status: EstimateStatuses.ACTUALISED },
      })
    );

    dispatch({
      type: CLOSE_ACTUALISATION_ESTIMATE_SUCCESS,
      estimateId,
      estimate,
      requestId,
    });
    dispatch(reset('submitForApproval'));
    dispatch(fetchEstimate(estimateId, requestId));
    dispatch(fetchWatchers(estimateId));
  } catch (error) {
    dispatch({ type: CLOSE_ACTUALISATION_ESTIMATE_ERROR, estimateId, error, requestId });
  }
};
