import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Grid, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Separator from 'components/common/separator';
import { font } from 'shared/styles';
import { loginWithUsername, login } from 'state/authentication/actions';
import Footer from 'components/common/footer';
import EmailPasswordForm from 'components/auth/emailPasswordLoginForm';
import SSOButtons from 'components/auth/SSOButtons';
import Notications from 'components/common/notifications';

const isDemoSite = window.location.host.includes('demo');

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  header: {
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    padding: !isDemoSite ? theme.spacing(4) : theme.spacing(2),
  },
  title: {
    width: 70,
    margin: 'auto',
    textAlign: 'right',
    color: 'white',
    lineHeight: '22px',
    letterSpacing: -1.3,
    fontWeight: font.weights.bold,
    fontSize: font.sizes.veryLarge,
    fontFamily: 'Montserrat,sans-serif',
    textDecoration: 'none',
  },
  moreOptionsButton: {
    cursor: 'pointer',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: `${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
  },

  errorContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  success: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Signup = ({
  hasAttemptedLogin,
  location,
  loading,
  login,
  loginWithEmailPassword,
  status,
  history,
}) => {
  const classes = useStyles();
  const alwaysShowEmailPassword = window.location.host.includes('pepsico');
  const [showEPLogin, setShowEPLogin] = useState(alwaysShowEmailPassword);
  const toggleShowEPLogin = () => setShowEPLogin(!showEPLogin);

  useEffect(() => {
    if (!hasAttemptedLogin) {
      login();
    }
  }, [hasAttemptedLogin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.container}
        data-test="login-container"
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper square>
            <Box className={classes.header}>
              {!isDemoSite ? (
                <div className={classes.title}>Control Room</div>
              ) : (
                <img
                  src="/images/cr-logo.png"
                  width="250px"
                  height="70px"
                  alt="control-room-logo"
                />
              )}
            </Box>
            <Box className={classes.body}>
              Welcome to Control Room Invest
              <SSOButtons />
              {!alwaysShowEmailPassword && (
                <Separator
                  text={showEPLogin ? 'Hide more options' : 'More options'}
                  className={classes.moreOptionsButton}
                  data-test="more-options"
                  onClick={toggleShowEPLogin}
                />
              )}
              {alwaysShowEmailPassword && (
                <Separator
                  text="Login with email and password"
                  className={classes.moreOptionsButton}
                />
              )}
              {showEPLogin && <EmailPasswordForm onSubmit={loginWithEmailPassword} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Notications />

      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  hasAttemptedLogin: state.auth.hasAttemptedLogin,
  status: state.resourceStatus.completeSignup,
});

const mapDispatchToProps = dispatch => ({
  loginWithEmailPassword: ({ email, password }) =>
    email && password && dispatch(loginWithUsername(email, password)),
  login: () => dispatch(login()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Signup);
