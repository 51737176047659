import { get } from 'lodash/fp';
import * as donwloadColumnsUtils from '../utils.downloadColumns';
import {
  campaignDivision,
  campaignPayingCountry,
  campaignProductCategory,
  campaignName,
  campaignStatus,
  campaignBudget,
  campaignLatestEstimate,
  campaignVariance,
  campaignActualised,
  campaignSavings,
} from '../utils.columns';

export default (data, displayCurrency) => [
  campaignDivision(),
  campaignPayingCountry(),
  campaignProductCategory(),
  {
    ...campaignName(),
    SubComponent: true,
    hasSubcomponent: get('original.estimates.length'),
  },
  campaignStatus(),
  campaignBudget({ data, displayCurrency }),
  campaignLatestEstimate({ data, displayCurrency }),
  campaignVariance({ data, displayCurrency }),
  campaignActualised({ data, displayCurrency }),
  campaignSavings({ data, displayCurrency }),
];

export const getDetailedDownloadColumns = () => {
  return [
    donwloadColumnsUtils.campaignBudgetYear,
    donwloadColumnsUtils.campaignProductCategory,
    donwloadColumnsUtils.campaignDivision,
    donwloadColumnsUtils.campaignClientIoNumber,
    donwloadColumnsUtils.campaignBudgetCenter,
    donwloadColumnsUtils.campaignWorkspaceName,
    donwloadColumnsUtils.campaignCountry,
    donwloadColumnsUtils.campaignNumber,
    donwloadColumnsUtils.campaignName,
    donwloadColumnsUtils.campaignOriginalsTotal,
    donwloadColumnsUtils.campaignEditsTotal,
    donwloadColumnsUtils.campaignAdditionalDeliverablesTotal,
    donwloadColumnsUtils.campaignBudget,
    donwloadColumnsUtils.campaignLatestEstimate,
    donwloadColumnsUtils.campaignActual,
    donwloadColumnsUtils.campaignVariance,
    donwloadColumnsUtils.campaignSavings,
    donwloadColumnsUtils.campaignStatus,
  ];
};

export const downloadColumns = [
  donwloadColumnsUtils.campaignDivision,
  donwloadColumnsUtils.campaignPayingCountry,
  donwloadColumnsUtils.campaignProductCategory,
  donwloadColumnsUtils.campaignName,
  donwloadColumnsUtils.campaignNumber,
  donwloadColumnsUtils.campaignStatus,
  donwloadColumnsUtils.campaignBudget,
  donwloadColumnsUtils.campaignLatestEstimate,
  donwloadColumnsUtils.campaignVariance,
  donwloadColumnsUtils.campaignActual,
  donwloadColumnsUtils.campaignSavings,
  donwloadColumnsUtils.campaignWorkspaceName,
];
