import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import useCreateSupplier from 'hooks/useCreateSupplier';
import useSupplier from 'hooks/useSupplier';
import useSupplierColumns from 'hooks/useSupplierColumns';
import useUpdateSupplier from 'hooks/useUpdateSupplier';
import React from 'react';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { displayNotification } from 'state/notifications/actions';
import { Notification } from 'types/notification';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

interface Props {
  open: boolean;
  accountId: string;
  supplierId?: string;
  onClose: () => void;
  showNotification: (notification: Notification) => void;
}

type Metadata = Record<string, { id?: string; value: string }>;

interface FormValues {
  name: string;
  wpp: string | null;
  metadata: Metadata;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const SupplierModal = ({ open, onClose, accountId, showNotification, supplierId }: Props) => {
  const isEditing = open && typeof supplierId !== 'undefined';

  const { data: columns, isLoading: isLoadingColumns } = useSupplierColumns(accountId);

  const { data: supplier, isLoading: isLoadingSupplier } = useSupplier(
    supplierId || '',
    accountId,
    {
      enabled: isEditing,
    }
  );

  const initialValues: FormValues = useMemo(
    () => ({
      name: supplier?.name ?? '',
      wpp: supplier?.wpp ?? null,
      metadata:
        columns?.reduce<Metadata>((acc, cur) => {
          acc[cur.id] = supplier?.metadata?.[cur.id] ?? { value: '' };
          return acc;
        }, {}) ?? {},
    }),
    [supplier, columns]
  );

  const { createSupplier, isLoading: isLoadingCreate } = useCreateSupplier();
  const { updateSupplier, isLoading: isLoadingUpdate } = useUpdateSupplier(
    supplierId || '',
    accountId
  );

  const isLoading = isLoadingCreate || isLoadingUpdate || isLoadingColumns;

  const handleSubmit = (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const onSettled = () => {
      setSubmitting(false);
    };

    const onError = (error: unknown) => {
      const message =
        (error as AxiosError).response?.data.message ||
        `Failed to ${isEditing ? 'update' : 'add'} supplier.`;

      showNotification({ type: 'error', message });
    };

    if (isEditing) {
      updateSupplier(values, {
        onSuccess: () => {
          showNotification({ type: 'success', message: 'Supplier updated successfully.' });
          onClose();
        },

        onError,
        onSettled,
      });
      return;
    }

    createSupplier(
      { accountId, ...values },
      {
        onSuccess: () => {
          showNotification({ type: 'success', message: 'Supplier added successfully.' });
          resetForm();
        },

        onError,
        onSettled,
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{isEditing ? 'Edit' : 'Add'} Supplier</DialogTitle>
      {isLoadingSupplier && (
        <Box display="flex" alignItems="center" justifyContent="center" height={200}>
          <CircularProgress />
        </Box>
      )}
      {!isLoadingSupplier && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <Form>
            <DialogContent>
              <Box mb={2}>
                <Field name="name" label="Name" fullWidth component={TextField} autoFocus />
              </Box>

              <WPP />

              {columns?.map(column => (
                <Box mb={2} key={column.id}>
                  <Field
                    name={`metadata.${column.id}.value`}
                    label={column.name}
                    fullWidth
                    component={TextField}
                  />
                </Box>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="contained">
                Cancel
              </Button>
              <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showNotification: (value: Notification) => {
    dispatch(displayNotification(value));
  },
});

export default connect(null, mapDispatchToProps)(SupplierModal);

const WPP = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const handleClick = (event: any) => {
    if (event.target.value === values.wpp) {
      setFieldValue('wpp', null);
    } else {
      setFieldValue('wpp', event.target.value);
    }
  };

  return (
    <Box mb={2}>
      <FormLabel component="legend">WPP / NON WPP</FormLabel>
      <RadioGroup name="wpp" value={values.wpp}>
        <FormControlLabel
          value="WPP"
          control={<Radio onClick={handleClick} color="primary" />}
          label="WPP"
        />
        <FormControlLabel
          value="NON WPP"
          control={<Radio onClick={handleClick} color="primary" />}
          label="NON WPP"
        />
        <FormControlLabel
          value="Colgate"
          control={<Radio onClick={handleClick} color="primary" />}
          label="Colgate"
        />
      </RadioGroup>
    </Box>
  );
};
