import React from 'react';
import clsx from 'clsx';
import color from 'color';
import { Link } from 'react-router-dom';
import { map, filter, flow, values, sum } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  AdditionalDeliverableLabels,
  AdditionalDeliverablesGroups,
  // EditTypeLables,
  MediaTypesWithDeliverables,
  AccountSetting,
  DeliverableOriginalTypes,
} from 'cr-core/constants';
import { Events, track, getEstimateData } from 'components/analytics';
import { colors } from 'shared/styles';
import { connect } from 'react-redux';
import { canEdit } from 'state/estimates/selectors';
import { getClientSettings, getSettings } from 'state/authentication/selectors';

const useStyles = makeStyles(theme => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '20px',
      color: colors.text,
      backgroundColor: 'white',
      border: '1px solid lightgray',
      borderRadius: '5px',
      padding: '10px',
    },
    typeTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),

      '&:first-child': {
        marginTop: 0,
      },
    },
    additionalDeliverablesTitle: {
      marginTop: theme.spacing(4),
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginBottom: theme.spacing(2),
    },
    group: {
      marginBottom: theme.spacing(2),
    },
    groupHeader: {
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
    },
    subGroup: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    warning: {
      backgroundColor: color(theme.palette.warning.light)
        .alpha(0.2)
        .string(),
      borderLeftWidth: theme.spacing(0.5),
      borderLeftColor: theme.palette.warning.main,
      borderLeftStyle: 'solid',
      padding: theme.spacing(1, 1.5),
      margin: theme.spacing(2, 1),
    },
  };
});

const NotSet = () => <i>not set</i>;

const mapStateToProps = (state, { estimate, clientSettings, settings }) => ({
  canEdit: canEdit(estimate ? estimate.id : undefined)(state),
  clientSettings: getClientSettings(state),
  settings: getSettings(state),
});

const Body = connect(mapStateToProps)(({ estimate, clientSettings, settings }) => {
  const classes = useStyles();
  // const editsCount = flow(values, sum)(estimate.edits);
  estimate.additionalDeliverables = estimate.additionalDeliverables || {};
  const additionalDeliverablesCount = flow(values, sum)(estimate.additionalDeliverables);

  return (
    <div>
      <div className={classes.summary}>
        <Typography variant="h6" className={classes.typeTitle}>
          Originals
        </Typography>
        <div className={classes.subGroup} data-test="orignals">
          {estimate.numberOfTVCs || <NotSet />}{' '}
          {estimate.originalsType && AdditionalDeliverableLabels[estimate.originalsType]}
          {clientSettings[AccountSetting.DeliverablesOriginalTypes] ||
          settings?.[estimate.campaign.workspace.accountId]?.[
            AccountSetting.DeliverablesOriginalTypes
          ]
            ? estimate.deliverableOriginalsType &&
              ` (${DeliverableOriginalTypes[estimate.deliverableOriginalsType]})`
            : estimate.clone && ' (Clone)'}
        </div>
        <Typography variant="h6" className={classes.typeTitle}>
          Edits
        </Typography>
        {/*<div data-test="edits">
          {map(
            ([type, count]) => (
              <div className={classes.subGroup}>
                {count} {EditTypeLables[type]}
              </div>
            ),
            toPairs(estimate.edits)
          )}
        </div>*/}

        <div className={classes.subGroup} data-test="edits">
          {estimate.numberOfRATVs || <NotSet />}
        </div>
        {/*<div className={classes.subGroup}>{!editsCount && <NotSet />}</div>*/}
      </div>
      {additionalDeliverablesCount > 0 && (
        <div>
          <Typography
            variant="h6"
            className={clsx(classes.typeTitle, classes.additionalDeliverablesTitle)}
          >
            Additional Deliverables
          </Typography>
          <div className={classes.listContainer} data-test="additional-deliverables">
            {map(({ name, deliverables }) => {
              const usedDeliverables = filter(
                type => estimate.additionalDeliverables[type],
                deliverables
              );
              if (!usedDeliverables.length) {
                return null;
              }
              return (
                <div className={classes.group}>
                  <div className={classes.groupHeader}>{name}</div>
                  {map(
                    type => (
                      <div className={classes.subGroup}>
                        {estimate.additionalDeliverables[type]} {AdditionalDeliverableLabels[type]}
                      </div>
                    ),
                    usedDeliverables
                  )}
                </div>
              );
            }, AdditionalDeliverablesGroups)}
          </div>
        </div>
      )}
    </div>
  );
});

const View = ({ estimate, onEditClick, canEdit }) => {
  const classes = useStyles();
  let message;
  let showActions = true;

  if (!MediaTypesWithDeliverables.includes(estimate.mediaType)) {
    message = "This estimate type doesn't support deliverables.";
    showActions = false;
  } else if (!estimate.numberOfTVCs && !estimate.numberOfRATVs) {
    message = 'No deliverables data found. Please edit to add estimate deliverables.';
  }
  // } else if (!estimate.originalsType && isEmpty(estimate.edits)) {
  //     message = 'No deliverables data found. Please edit to add estimate deliverables.';
  //   }

  return (
    <div className={classes.container}>
      {message && (
        <div>
          <Alert severity="warning" className={classes.alert} data-test="error-message">
            {message}
          </Alert>
        </div>
      )}
      {!message && <Body estimate={estimate} />}

      {canEdit && showActions && (
        <div className={classes.actionsContainer}>
          <Button
            variant="contained"
            data-test="editDeliverables"
            component={Link}
            to={`/estimates/${estimate.id}/deliverables/edit`}
            onClick={() => track(Events.ESTIMATE_DELIVERABLES_EDIT, getEstimateData(estimate))}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(View);
