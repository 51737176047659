import React, { useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider, connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import styled from 'styled-components';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import config from 'config';
import CreateEstimate from 'components/estimateCreate';
import EstimateHistory from 'components/estimateHistory';
import { includeSegment } from 'components/segment';
import Reports from 'pages/reports';
import { FullScreenLoader } from 'components/spinner';
import NewUserList from 'pages/users';
import WorkspacesPage from 'pages/workspaces';
import TeamsPage from 'pages/teams';
import AccountSettings from 'pages/accountSettings';
import EstimateDetail from 'pages/estimateDetail';
import Estimates from 'pages/estimates';
import Campaigns from 'pages/campaigns';
import NoWorkspaces from 'pages/noWorkspaces';
import LoginNew from 'pages/login';
import Signup from 'pages/signup';
import NotFound from 'pages/404';
import PasswordReset from 'pages/resetPassword';
import TermsAndConditions from 'pages/termsAndConditions';
import Admin from 'pages/admin';
import { inputStyles, textareaStyles } from 'shared/styles';
import { checkAuth } from 'state/authentication/actions';
import { getUser } from 'state/authentication/selectors';
import store, { history } from 'state/store';
import * as authService from 'state/authentication/authService';
import { clearInterceptors, setInterceptors } from 'services/ApiService';
import { QueryClientProvider, QueryClient, QueryCache } from 'react-query';
import Home from 'pages/Home';
import StrongPasswordGuidelines from 'pages/StrongPasswordGuidelines';
import Zendesk from 'react-zendesk';
import Smartlook from 'components/Smartlook';

if (config.REACT_APP_SENTRY_URL !== 'false') {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_URL,
    release: config.REACT_APP_VERSION,
    environment: config.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

includeSegment();

store.dispatch(checkAuth());

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f87b6',
    },
    success: {
      main: '#4caf50',
    },
  },
  breakpoints: {
    values: { xs: 0, sm: 800, md: 1366, lg: 1600, xl: 1920 },
  },
});

const mapStateToProps = (state: any) => {
  const { isAuthenticated, authChecked, isLoggingIn } = state.auth;
  return {
    isAuthenticated,
    authChecked,
    isLoggingIn,
    user: getUser(state),
  };
};

const SecureApp = connect(mapStateToProps)(
  ({ className, isAuthenticated, authChecked, isLoggingIn, user }) => {
    if (isLoggingIn || !authChecked) {
      return <FullScreenLoader />;
    }

    const target = authService.getTarget();
    if (target && isAuthenticated) {
      authService.clearTarget();
      history.push(target);
    }

    return (
      <>
        {isAuthenticated ? (
          <ConnectedRouter history={history}>
            <div className={className}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/estimates/new" component={CreateEstimate} />
                <Route path="/estimates/:id/history" component={EstimateHistory} />
                <Route
                  path="/estimates/:id"
                  render={props => <EstimateDetail {...props} path="/estimates/:id" />}
                />
                <Route path="/estimates" component={Estimates} />
                <Route path="/campaigns" component={Campaigns} />
                <Route path="/users/:userId" component={NewUserList} />
                <Route path="/users" component={NewUserList} />
                <Route path="/workspaces/:workspaceId" component={WorkspacesPage} />
                <Route path="/workspaces" component={WorkspacesPage} />
                <Route path="/teams/:teamId" component={TeamsPage} />
                <Route path="/teams" component={TeamsPage} />
                <Route path="/reports/:reportId?" component={Reports} />
                <Route path="/account-settings" component={AccountSettings} />

                {(user as any).isAdmin && <Route path="/admin" component={Admin} />}
                {/* @ts-ignore fix later */}
                <Route path="/no-workspaces" render={NoWorkspaces} />
                {/* @ts-ignore fix later */}
                <Route path="/404" render={NotFound} />
                <Redirect from="/" to="/campaigns" />
              </Switch>
            </div>
          </ConnectedRouter>
        ) : (
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/password-reset" component={PasswordReset} />
              <Route path="/signup" component={Signup} />
              <Route path="/password-setting-guidelines" component={StrongPasswordGuidelines} />
              <Route path="/login" component={LoginNew} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route component={LoginNew} />
            </Switch>
          </ConnectedRouter>
        )}
        {process.env.REACT_APP_ENV === 'prod' && (
          <Smartlook isAuthenticated={isAuthenticated} user={user} />
        )}
      </>
    );
  }
);

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const getClient = () => {
  const hostnameParts = window.location.hostname.split('.');
  return hostnameParts.length === 4
    ? hostnameParts[0]
    : hostnameParts[0] === 'app-demo'
    ? 'demo'
    : 'colgate';
};

const client = getClient();

const zendeskKeys: Record<string, string> = {
  pepsico: 'b6d6e103-a16b-4924-b290-f22717177b2d',
  colgate: 'cadc03f6-5eb3-45e0-9c97-a05a63013aa8',
};

const zendeskKey = zendeskKeys[client];

const App = ({ className }: { className: string }) => {
  useLayoutEffect(() => {
    setInterceptors();

    return () => clearInterceptors();
  }, []);

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            {/* @ts-ignore fix later */}
            <SecureApp className={className} />
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
      {process.env.REACT_APP_ENV === 'dev' && zendeskKey ? (
        <Zendesk defer zendeskKey={zendeskKey} />
      ) : null}
    </>
  );
};
export default styled(App)`
  .textInput {
    ${inputStyles}
  }

  textarea {
    ${textareaStyles}
  }
`;
