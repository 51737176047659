import React from 'react';
// import { flow, map, uniq, flatten } from 'lodash/fp';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import EstimateTypeChart from '../charts/estimateType';
import BudgetsChart from '../charts/budgets';
import EstimateStatusChart from '../charts/estimateStatus';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    alignItems: 'stretch',
  },
}));

const Charts = ({ estimates, campaigns, indexPropGet, displayCurrency }) => {
  const classes = useStyles();
  const category = indexPropGet('brandCategory', 'Categories')(estimates);
  const indexPropGetFn = category.startsWith('Multiple')
    ? indexPropGet('brandCategory', 'Categories')
    : indexPropGet('brandName', 'Brands');
  const breakdownLevel = category.startsWith('Multiple') ? 'Category' : 'Brand';

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item lg={4} xs={12}>
          <EstimateTypeChart estimates={estimates} displayCurrency={displayCurrency} />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <BudgetsChart
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            title={`${breakdownLevel} Budget`}
            indexPropGetFn={indexPropGetFn}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <EstimateStatusChart
            estimates={estimates}
            campaigns={campaigns}
            displayCurrency={displayCurrency}
            title={`Budget breakdown by ${breakdownLevel}`}
            indexPropGetFn={indexPropGetFn}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Charts;
