import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Grid, Box, Paper, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Separator from 'components/common/separator';
import { font } from 'shared/styles';
import { verifySignupCode, completeSignup } from 'state/authentication/actions';
import { getSignupCodeVerified } from 'state/authentication/selectors';
import Footer from 'components/common/footer';
import queryString from 'query-string';
import { LOADING, SUCCESS } from 'state/resourceStatus/reducer';
import EmailPasswordForm from 'components/auth/emailPasswordSignupForm';
import SSOButtons from 'components/auth/SSOButtons';
import { setShowEPLogin } from 'state/ui/actions';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  header: {
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  title: {
    width: 70,
    margin: 'auto',
    textAlign: 'right',
    color: 'white',
    lineHeight: '22px',
    letterSpacing: -1.3,
    fontWeight: font.weights.bold,
    fontSize: font.sizes.veryLarge,
    fontFamily: 'Montserrat,sans-serif',
    textDecoration: 'none',
  },
  moreOptionsButton: {
    cursor: 'pointer',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: `${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  loaderContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  loader: {
    margin: 'auto',
  },
  errorContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  success: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loginButton: {
    marginTop: theme.spacing(2),
  },
}));

const Signup = ({
  hasAttemptedLogin,
  location,
  verifySignupCode,
  verified,
  loading,
  completeSignup,
  status,
  history,
  showEPLogin,
  setShowEPLogin,
}) => {
  const { crbcode: code } = queryString.parse(location.search);

  const classes = useStyles();
  const alwaysShowEmailPassword = window.location.host.includes('pepsico');

  const toggleShowEPLogin = () => !alwaysShowEmailPassword && setShowEPLogin(!showEPLogin);

  useEffect(() => {
    verifySignupCode(code);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper square>
            <Box className={classes.header}>
              <div className={classes.title}>Control Room</div>
            </Box>
            <Box className={classes.body}>
              {loading && (
                <div className={classes.loaderContainer}>
                  <CircularProgress className={classes.loader} />
                  <div>Verifing invitation...</div>
                </div>
              )}
              {!loading && !verified && (
                <div className={classes.errorContainer}>This link is not valid anymore</div>
              )}
              {!loading && status === SUCCESS && (
                <div className={classes.success}>
                  Sing up completed.
                  <br />
                  <Button
                    variant="contained"
                    className={classes.loginButton}
                    onClick={() => history.push('/login')}
                  >
                    Click here to login
                  </Button>
                </div>
              )}
              {verified && status !== SUCCESS && (
                <>
                  {(alwaysShowEmailPassword || !showEPLogin) && (
                    <>
                      {!alwaysShowEmailPassword && (
                        <div className={classes.info}>
                          To complete the signup please click one of the button below or "more
                          option" if you don't have any of the supported SSO
                        </div>
                      )}
                      <SSOButtons signup={true} />
                    </>
                  )}
                  <Separator
                    text={
                      showEPLogin && !alwaysShowEmailPassword ? 'Show SSO options' : 'More options'
                    }
                    className={classes.moreOptionsButton}
                    data-test="more-options"
                    onClick={toggleShowEPLogin}
                  />
                  {showEPLogin && (
                    <>
                      <div className={classes.info}>
                        If you don't have one of the supported SSO, please sign up using the form
                        below
                      </div>
                      <EmailPasswordForm onSubmit={completeSignup(code)} />
                    </>
                  )}
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  hasAttemptedLogin: state.auth.hasAttemptedLogin,
  verified: getSignupCodeVerified(state),
  loading: state.resourceStatus.authVerifySignupCode === LOADING,
  status: state.resourceStatus.completeSignup,
  showEPLogin: state.ui.showEPLogin,
});

const mapDispatchToProps = dispatch => ({
  verifySignupCode: code => dispatch(verifySignupCode(code)),
  completeSignup: code => data => dispatch(completeSignup(code, data)),
  setShowEPLogin: showEPLogin => dispatch(setShowEPLogin(showEPLogin)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Signup);
