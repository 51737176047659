import {
  estimateName,
  estimateCampaignProductCategory,
  estimateCampaignName,
  estimateCampaignBudget,
  estimateLatestEstimate,
  estimateActualised,
  estimateCampaignVariance,
  estimateCampaignSavings,
  estimateBundle,
} from '../utils.columns';

import * as donwloadColumnsUtils from '../utils.downloadColumns';
import { COLUMNS } from '../utils.legend';
import { AccountSetting } from 'cr-core/constants';
import { compact } from 'lodash/fp';

export default (data, displayCurrency, clientSettings) => {
  const columns = [
    {
      ...estimateCampaignProductCategory(),
      Footer: () => 'Total',
    },
    estimateCampaignName(),
    estimateName(),
    estimateCampaignBudget({ displayCurrency }),
    { ...estimateLatestEstimate({ displayCurrency }), description: COLUMNS.campaignLatestEstimate },
    estimateCampaignVariance({ displayCurrency }),
    { ...estimateActualised({ displayCurrency }), description: COLUMNS.campaignActualised },
    estimateCampaignSavings({ displayCurrency }),
  ];

  clientSettings[AccountSetting.EstimateBundle] && columns.splice(3, 0, estimateBundle());

  return columns;
};

export const getDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    ...donwloadColumnsUtils.estimateInfo(clientSettings, true),
    donwloadColumnsUtils.estimateCampaignStatus,
    donwloadColumnsUtils.estimateCampaignProductCategory,
    donwloadColumnsUtils.estimateCampaignBudget,
    donwloadColumnsUtils.estimateCampaignLatestEstimate,
    donwloadColumnsUtils.estimateCampaignRemaingBudget,
    donwloadColumnsUtils.estimateCampaignActualised,
    donwloadColumnsUtils.estimateCampaignSavings,
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
  ]);

export const getDetailedDownloadColumns = ({ clientSettings = {} }) =>
  compact([
    donwloadColumnsUtils.estimateBudgetYear,
    donwloadColumnsUtils.estimateDivision,
    donwloadColumnsUtils.estimateCampaignClientIoNumber,
    donwloadColumnsUtils.estimateBudgetCenter,
    donwloadColumnsUtils.workspaceName,
    donwloadColumnsUtils.estimateCampaignProductCategory,
    donwloadColumnsUtils.estimateCampaignProductBrand,
    donwloadColumnsUtils.estimateCampaignNumber,
    donwloadColumnsUtils.estimateCampaignName,
    donwloadColumnsUtils.estimateCampaignBudget,
    donwloadColumnsUtils.estimateCampaignLatestEstimate,
    donwloadColumnsUtils.estimateCampaignActualised,
    donwloadColumnsUtils.estimateCampaignRemaingBudget,
    donwloadColumnsUtils.estimateCampaignSavings,
    donwloadColumnsUtils.estimateCampaignStatus,
    donwloadColumnsUtils.estimateType,
    donwloadColumnsUtils.estimateName,
    donwloadColumnsUtils.latestEstimate,
    donwloadColumnsUtils.estimateActual,
    donwloadColumnsUtils.estimatePayingCountry,
    donwloadColumnsUtils.estimateLeadMarket,
    clientSettings[AccountSetting.EstimateBundle] && donwloadColumnsUtils.estimateBundle,
    donwloadColumnsUtils.estimateStatus,
    donwloadColumnsUtils.estimateLink,
  ]);
