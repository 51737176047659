import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, find, flow, get, map, filter } from 'lodash/fp';
import { compose, getContext, withProps, branch, renderComponent } from 'recompose';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getCostCategoryById } from 'state/costCategories/selectors';
import { getLineItemBeingAddedToCostCategory } from 'state/bids/selectors';
import { SubRowsContainer } from './tableComponents';
import AddLineItemButton from './addLineItemButton';
import LineItemSelect from './lineItemSelect';
import LineItemRow from './lineItemRow';
import useEmptyLineItemsFilter from 'hooks/useEmptyLineItemsFilter';

const DraggableLineItemsGroup = ({
  lineItemIds,
  costCategoryId,
  costCategoryName,
  lineItemBeingAdded,
  hasLineItems,
  estimate,
  isMetadata = false,
  lastLineItemIdAdded,
  pageId,
}) => {
  const ids = useEmptyLineItemsFilter(estimate, lineItemIds);

  return (
    <Droppable droppableId={costCategoryId} type={costCategoryId}>
      {(provided, snapshot) => (
        <SubRowsContainer data-test="costCategoryLineItems" ref={provided.innerRef}>
          {ids.map((lineItemId, index) => (
            <Draggable
              key={lineItemId}
              draggableId={lineItemId}
              index={index}
              type={costCategoryId}
            >
              {(provided, snapshot) => (
                <LineItemRow
                  className={isMetadata ? 'metadata' : ''}
                  ref={provided.innerRef}
                  draggableProps={provided.draggableProps}
                  dragHandleProps={provided.dragHandleProps}
                  key={lineItemId}
                  lineItemId={lineItemId}
                  costCategoryId={costCategoryId}
                  pageId={pageId}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {!isMetadata && (lineItemBeingAdded || !hasLineItems) && (
            <LineItemSelect costCategoryId={costCategoryId} estimate={estimate} pageId={pageId} />
          )}
          {!isMetadata && !lineItemBeingAdded && hasLineItems && (
            <AddLineItemButton
              costCategoryId={costCategoryId}
              costCategoryName={costCategoryName}
            />
          )}
        </SubRowsContainer>
      )}
    </Droppable>
  );
};

const DraggableLineItems = ({
  costLineItemIds,
  metadaLineItemIds,
  costCategoryId,
  costCategory,
  lineItemBeingAdded,
  hasLineItems,
  estimate,
  lastLineItemIdAdded,
  pageId,
}) => (
  <>
    <DraggableLineItemsGroup
      lineItemIds={metadaLineItemIds}
      costCategoryId={costCategoryId}
      lineItemBeingAdded={lineItemBeingAdded}
      hasLineItems={hasLineItems}
      estimate={estimate}
      isMetadata={true}
      lastLineItemIdAdded={lastLineItemIdAdded}
      pageId={pageId}
    />
    <DraggableLineItemsGroup
      lineItemIds={costLineItemIds}
      costCategoryId={costCategoryId}
      lineItemBeingAdded={lineItemBeingAdded}
      hasLineItems={hasLineItems}
      estimate={estimate}
      lastLineItemIdAdded={lastLineItemIdAdded}
      costCategoryName={costCategory && costCategory.name}
      pageId={pageId}
    />
  </>
);

const StaticLineItemsGroup = ({ costCategoryId, lineItemIds, pageId, estimate }) => {
  const ids = useEmptyLineItemsFilter(estimate, lineItemIds);

  return (
    <SubRowsContainer data-test="something">
      {ids.map((lineItemId, index) => (
        <LineItemRow
          key={lineItemId}
          lineItemId={lineItemId}
          costCategoryId={costCategoryId}
          pageId={pageId}
        />
      ))}
    </SubRowsContainer>
  );
};

const StaticLineItems = ({
  costCategoryId,
  costLineItemIds,
  metadaLineItemIds,
  pageId,
  estimate,
}) => (
  <>
    <StaticLineItemsGroup
      costCategoryId={costCategoryId}
      lineItemIds={metadaLineItemIds}
      pageId={pageId}
      estimate={estimate}
    />
    <StaticLineItemsGroup
      costCategoryId={costCategoryId}
      lineItemIds={costLineItemIds}
      pageId={pageId}
      estimate={estimate}
    />
  </>
);

const mapStateToProps = (state, { costCategoryId, estimate }) => {
  const lineItems = flow(
    get('lineItemGroups'),
    find({ id: costCategoryId }),
    get('lineItemNames')
  )(estimate);
  return {
    costCategory: getCostCategoryById(costCategoryId)(state),
    lineItemBeingAdded: getLineItemBeingAddedToCostCategory(costCategoryId)(state),
    lineItemIds: map('id', lineItems),
    costLineItemIds: flow(filter({ type: 'cost' }), map('id'))(lineItems),
    metadaLineItemIds: flow(
      filter(item => item),
      filter(({ type }) => type !== 'cost'),
      map('id')
    )(lineItems),
  };
};

const LineItems = compose(
  getContext({ estimate: PropTypes.object, readOnly: PropTypes.bool }),
  connect(mapStateToProps),
  withProps(({ lineItemIds }) => ({ hasLineItems: !isEmpty(lineItemIds) })),
  branch(({ readOnly }) => !readOnly, renderComponent(DraggableLineItems))
)(StaticLineItems);

export default LineItems;
