import { flow, get, map, uniq, flatten, compact } from 'lodash/fp';
import { getDivision } from '../utils';

export const getDivisions = flow(
  map('customData.payingCountries'),
  map((values = []) => (values.length ? values : ['Not Set'])),
  flatten,
  map(getDivision),
  uniq
);

export const indexPropGetFnDivision = flow(getDivisions, divisions => {
  return divisions.length === 0
    ? 'Not Set'
    : divisions.length === 1
    ? divisions[0]
    : 'Multiple Divisions';
});

export const indexPropGetFnCountry = flow(
  map('customData.payingCountries'),
  compact,
  map(values => (get('length', values) ? values : ['Not Set'])),
  flatten,
  uniq,
  categories =>
    categories.length === 0
      ? 'Not Set'
      : categories.length === 1
      ? categories[0]
      : 'Multiple Countries'
);
