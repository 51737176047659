export default [{
  value: 'usd',
  label: 'USD ($)'
}, {
  value: 'gbp',
  label: 'GBP (£)'
}, {
  value: 'eur',
  label: 'EUR (€)'
}, {
  value: 'cny',
  label: 'CNY (¥)'
}, {
  value: 'cop',
  label: 'COP (COL$)'
}, {
  value: 'thb',
  label: 'THB (฿)'
}, {
  value: 'aed',
  label: 'AED'
}, {
  value: 'afn',
  label: 'AFN'
}, {
  value: 'all',
  label: 'ALL'
}, {
  value: 'amd',
  label: 'AMD'
}, {
  value: 'ang',
  label: 'ANG'
}, {
  value: 'aoa',
  label: 'AOA'
}, {
  value: 'ars',
  label: 'ARS'
}, {
  value: 'aud',
  label: 'AUD'
}, {
  value: 'awg',
  label: 'AWG'
}, {
  value: 'azn',
  label: 'AZN'
}, {
  value: 'bam',
  label: 'BAM'
}, {
  value: 'bbd',
  label: 'BBD'
}, {
  value: 'bdt',
  label: 'BDT'
}, {
  value: 'bgn',
  label: 'BGN'
}, {
  value: 'bhd',
  label: 'BHD'
}, {
  value: 'bif',
  label: 'BIF'
}, {
  value: 'bmd',
  label: 'BMD'
}, {
  value: 'bnd',
  label: 'BND'
}, {
  value: 'bob',
  label: 'BOB'
}, {
  value: 'brl',
  label: 'BRL'
}, {
  value: 'bsd',
  label: 'BSD'
}, {
  value: 'btc',
  label: 'BTC'
}, {
  value: 'btn',
  label: 'BTN'
}, {
  value: 'bwp',
  label: 'BWP'
}, {
  value: 'byn',
  label: 'BYN'
}, {
  value: 'byr',
  label: 'BYR'
}, {
  value: 'bzd',
  label: 'BZD'
}, {
  value: 'cad',
  label: 'CAD'
}, {
  value: 'cdf',
  label: 'CDF'
}, {
  value: 'chf',
  label: 'CHF'
}, {
  value: 'clf',
  label: 'CLF'
}, {
  value: 'clp',
  label: 'CLP'
}, {
  value: 'cop',
  label: 'COP'
}, {
  value: 'crc',
  label: 'CRC'
}, {
  value: 'cuc',
  label: 'CUC'
}, {
  value: 'cup',
  label: 'CUP'
}, {
  value: 'cve',
  label: 'CVE'
}, {
  value: 'czk',
  label: 'CZK'
}, {
  value: 'djf',
  label: 'DJF'
}, {
  value: 'dkk',
  label: 'DKK'
}, {
  value: 'dop',
  label: 'DOP'
}, {
  value: 'dzd',
  label: 'DZD'
}, {
  value: 'egp',
  label: 'EGP'
}, {
  value: 'ern',
  label: 'ERN'
}, {
  value: 'etb',
  label: 'ETB'
}, {
  value: 'fjd',
  label: 'FJD'
}, {
  value: 'fkp',
  label: 'FKP'
}, {
  value: 'gel',
  label: 'GEL'
}, {
  value: 'ggp',
  label: 'GGP'
}, {
  value: 'ghs',
  label: 'GHS'
}, {
  value: 'gip',
  label: 'GIP'
}, {
  value: 'gmd',
  label: 'GMD'
}, {
  value: 'gnf',
  label: 'GNF'
}, {
  value: 'gtq',
  label: 'GTQ'
}, {
  value: 'gyd',
  label: 'GYD'
}, {
  value: 'hkd',
  label: 'HKD'
}, {
  value: 'hnl',
  label: 'HNL'
}, {
  value: 'hrk',
  label: 'HRK'
}, {
  value: 'htg',
  label: 'HTG'
}, {
  value: 'huf',
  label: 'HUF'
}, {
  value: 'idr',
  label: 'IDR'
}, {
  value: 'ils',
  label: 'ILS'
}, {
  value: 'imp',
  label: 'IMP'
}, {
  value: 'inr',
  label: 'INR'
}, {
  value: 'iqd',
  label: 'IQD'
}, {
  value: 'irr',
  label: 'IRR'
}, {
  value: 'isk',
  label: 'ISK'
}, {
  value: 'jep',
  label: 'JEP'
}, {
  value: 'jmd',
  label: 'JMD'
}, {
  value: 'jod',
  label: 'JOD'
}, {
  value: 'jpy',
  label: 'JPY'
}, {
  value: 'kes',
  label: 'KES'
}, {
  value: 'kgs',
  label: 'KGS'
}, {
  value: 'khr',
  label: 'KHR'
}, {
  value: 'kmf',
  label: 'KMF'
}, {
  value: 'kpw',
  label: 'KPW'
}, {
  value: 'krw',
  label: 'KRW'
}, {
  value: 'kwd',
  label: 'KWD'
}, {
  value: 'kyd',
  label: 'KYD'
}, {
  value: 'kzt',
  label: 'KZT'
}, {
  value: 'lak',
  label: 'LAK'
}, {
  value: 'lbp',
  label: 'LBP'
}, {
  value: 'lkr',
  label: 'LKR'
}, {
  value: 'lrd',
  label: 'LRD'
}, {
  value: 'lsl',
  label: 'LSL'
}, {
  value: 'ltl',
  label: 'LTL'
}, {
  value: 'lvl',
  label: 'LVL'
}, {
  value: 'lyd',
  label: 'LYD'
}, {
  value: 'mad',
  label: 'MAD'
}, {
  value: 'mdl',
  label: 'MDL'
}, {
  value: 'mga',
  label: 'MGA'
}, {
  value: 'mkd',
  label: 'MKD'
}, {
  value: 'mmk',
  label: 'MMK'
}, {
  value: 'mnt',
  label: 'MNT'
}, {
  value: 'mop',
  label: 'MOP'
}, {
  value: 'mro',
  label: 'MRO'
}, {
  value: 'mur',
  label: 'MUR'
}, {
  value: 'mvr',
  label: 'MVR'
}, {
  value: 'mwk',
  label: 'MWK'
}, {
  value: 'mxn',
  label: 'MXN'
}, {
  value: 'myr',
  label: 'MYR'
}, {
  value: 'mzn',
  label: 'MZN'
}, {
  value: 'nad',
  label: 'NAD'
}, {
  value: 'ngn',
  label: 'NGN'
}, {
  value: 'nio',
  label: 'NIO'
}, {
  value: 'nok',
  label: 'NOK'
}, {
  value: 'npr',
  label: 'NPR'
}, {
  value: 'nzd',
  label: 'NZD'
}, {
  value: 'omr',
  label: 'OMR'
}, {
  value: 'pab',
  label: 'PAB'
}, {
  value: 'pen',
  label: 'PEN'
}, {
  value: 'pgk',
  label: 'PGK'
}, {
  value: 'php',
  label: 'PHP'
}, {
  value: 'pkr',
  label: 'PKR'
}, {
  value: 'pln',
  label: 'PLN'
}, {
  value: 'pyg',
  label: 'PYG'
}, {
  value: 'qar',
  label: 'QAR'
}, {
  value: 'ron',
  label: 'RON'
}, {
  value: 'rsd',
  label: 'RSD'
}, {
  value: 'rub',
  label: 'RUB'
}, {
  value: 'rwf',
  label: 'RWF'
}, {
  value: 'sar',
  label: 'SAR'
}, {
  value: 'sbd',
  label: 'SBD'
}, {
  value: 'scr',
  label: 'SCR'
}, {
  value: 'sdg',
  label: 'SDG'
}, {
  value: 'sek',
  label: 'SEK'
}, {
  value: 'sgd',
  label: 'SGD'
}, {
  value: 'shp',
  label: 'SHP'
}, {
  value: 'sll',
  label: 'SLL'
}, {
  value: 'sos',
  label: 'SOS'
}, {
  value: 'srd',
  label: 'SRD'
}, {
  value: 'std',
  label: 'STD'
}, {
  value: 'svc',
  label: 'SVC'
}, {
  value: 'syp',
  label: 'SYP'
}, {
  value: 'szl',
  label: 'SZL'
}, {
  value: 'tjs',
  label: 'TJS'
}, {
  value: 'tmt',
  label: 'TMT'
}, {
  value: 'tnd',
  label: 'TND'
}, {
  value: 'top',
  label: 'TOP'
}, {
  value: 'try',
  label: 'TRY'
}, {
  value: 'ttd',
  label: 'TTD'
}, {
  value: 'twd',
  label: 'TWD'
}, {
  value: 'tzs',
  label: 'TZS'
}, {
  value: 'uah',
  label: 'UAH'
}, {
  value: 'ugx',
  label: 'UGX'
}, {
  value: 'uyu',
  label: 'UYU'
}, {
  value: 'uzs',
  label: 'UZS'
}, {
  value: 'vef',
  label: 'VEF'
}, {
  value: 'vnd',
  label: 'VND'
}, {
  value: 'vuv',
  label: 'VUV'
}, {
  value: 'wst',
  label: 'WST'
}, {
  value: 'xaf',
  label: 'XAF'
}, {
  value: 'xag',
  label: 'XAG'
}, {
  value: 'xau',
  label: 'XAU'
}, {
  value: 'xcd',
  label: 'XCD'
}, {
  value: 'xdr',
  label: 'XDR'
}, {
  value: 'xof',
  label: 'XOF'
}, {
  value: 'xpf',
  label: 'XPF'
}, {
  value: 'yer',
  label: 'YER'
}, {
  value: 'zar',
  label: 'ZAR'
}, {
  value: 'zmk',
  label: 'ZMK'
}, {
  value: 'zmw',
  label: 'ZMW'
}, {
  value: 'zwl',
  label: 'ZWL'
}];
export var updatedCurrencies = [{
  value: 'usd',
  label: 'USD ($)'
}, {
  value: 'gbp',
  label: 'GBP (£)'
}, {
  value: 'eur',
  label: 'EUR (€)'
}, {
  value: 'cny',
  label: 'CNY (¥)'
}, {
  value: 'cop',
  label: 'COP (COL$)'
}, {
  value: 'thb',
  label: 'THB (฿)'
}, {
  value: 'aed',
  label: 'AED'
}, {
  value: 'afn',
  label: 'AFN'
}, {
  value: 'all',
  label: 'ALL'
}, {
  value: 'amd',
  label: 'AMD'
}, {
  value: 'ang',
  label: 'ANG'
}, {
  value: 'aoa',
  label: 'AOA'
}, {
  value: 'ars',
  label: 'ARS'
}, {
  value: 'aud',
  label: 'AUD'
}, {
  value: 'awg',
  label: 'AWG'
}, {
  value: 'azn',
  label: 'AZN'
}, {
  value: 'bam',
  label: 'BAM'
}, {
  value: 'bbd',
  label: 'BBD'
}, {
  value: 'bdt',
  label: 'BDT'
}, {
  value: 'bgn',
  label: 'BGN'
}, {
  value: 'bhd',
  label: 'BHD'
}, {
  value: 'bif',
  label: 'BIF'
}, {
  value: 'bmd',
  label: 'BMD'
}, {
  value: 'bnd',
  label: 'BND'
}, {
  value: 'bob',
  label: 'BOB'
}, {
  value: 'brl',
  label: 'BRL'
}, {
  value: 'bsd',
  label: 'BSD'
}, {
  value: 'btc',
  label: 'BTC'
}, {
  value: 'btn',
  label: 'BTN'
}, {
  value: 'bwp',
  label: 'BWP'
}, {
  value: 'byn',
  label: 'BYN'
}, {
  value: 'bzd',
  label: 'BZD'
}, {
  value: 'cad',
  label: 'CAD'
}, {
  value: 'cdf',
  label: 'CDF'
}, {
  value: 'chf',
  label: 'CHF'
}, {
  value: 'clf',
  label: 'CLF'
}, {
  value: 'clp',
  label: 'CLP'
}, {
  value: 'cop',
  label: 'COP'
}, {
  value: 'crc',
  label: 'CRC'
}, {
  value: 'cuc',
  label: 'CUC'
}, {
  value: 'cup',
  label: 'CUP'
}, {
  value: 'cve',
  label: 'CVE'
}, {
  value: 'czk',
  label: 'CZK'
}, {
  value: 'djf',
  label: 'DJF'
}, {
  value: 'dkk',
  label: 'DKK'
}, {
  value: 'dop',
  label: 'DOP'
}, {
  value: 'dzd',
  label: 'DZD'
}, {
  value: 'egp',
  label: 'EGP'
}, {
  value: 'ern',
  label: 'ERN'
}, {
  value: 'etb',
  label: 'ETB'
}, {
  value: 'fjd',
  label: 'FJD'
}, {
  value: 'fkp',
  label: 'FKP'
}, {
  value: 'gel',
  label: 'GEL'
}, {
  value: 'ggp',
  label: 'GGP'
}, {
  value: 'ghs',
  label: 'GHS'
}, {
  value: 'gip',
  label: 'GIP'
}, {
  value: 'gmd',
  label: 'GMD'
}, {
  value: 'gnf',
  label: 'GNF'
}, {
  value: 'gtq',
  label: 'GTQ'
}, {
  value: 'gyd',
  label: 'GYD'
}, {
  value: 'hkd',
  label: 'HKD'
}, {
  value: 'hnl',
  label: 'HNL'
}, {
  value: 'hrk',
  label: 'HRK'
}, {
  value: 'htg',
  label: 'HTG'
}, {
  value: 'huf',
  label: 'HUF'
}, {
  value: 'idr',
  label: 'IDR'
}, {
  value: 'ils',
  label: 'ILS'
}, {
  value: 'imp',
  label: 'IMP'
}, {
  value: 'inr',
  label: 'INR'
}, {
  value: 'iqd',
  label: 'IQD'
}, {
  value: 'irr',
  label: 'IRR'
}, {
  value: 'isk',
  label: 'ISK'
}, {
  value: 'jep',
  label: 'JEP'
}, {
  value: 'jmd',
  label: 'JMD'
}, {
  value: 'jod',
  label: 'JOD'
}, {
  value: 'jpy',
  label: 'JPY'
}, {
  value: 'kes',
  label: 'KES'
}, {
  value: 'kgs',
  label: 'KGS'
}, {
  value: 'khr',
  label: 'KHR'
}, {
  value: 'kmf',
  label: 'KMF'
}, {
  value: 'kpw',
  label: 'KPW'
}, {
  value: 'krw',
  label: 'KRW'
}, {
  value: 'kwd',
  label: 'KWD'
}, {
  value: 'kyd',
  label: 'KYD'
}, {
  value: 'kzt',
  label: 'KZT'
}, {
  value: 'lak',
  label: 'LAK'
}, {
  value: 'lbp',
  label: 'LBP'
}, {
  value: 'lkr',
  label: 'LKR'
}, {
  value: 'lrd',
  label: 'LRD'
}, {
  value: 'lsl',
  label: 'LSL'
}, {
  value: 'lyd',
  label: 'LYD'
}, {
  value: 'mad',
  label: 'MAD'
}, {
  value: 'mdl',
  label: 'MDL'
}, {
  value: 'mga',
  label: 'MGA'
}, {
  value: 'mkd',
  label: 'MKD'
}, {
  value: 'mmk',
  label: 'MMK'
}, {
  value: 'mnt',
  label: 'MNT'
}, {
  value: 'mop',
  label: 'MOP'
}, {
  value: 'mur',
  label: 'MUR'
}, {
  value: 'mvr',
  label: 'MVR'
}, {
  value: 'mwk',
  label: 'MWK'
}, {
  value: 'mxn',
  label: 'MXN'
}, {
  value: 'myr',
  label: 'MYR'
}, {
  value: 'mzn',
  label: 'MZN'
}, {
  value: 'nad',
  label: 'NAD'
}, {
  value: 'ngn',
  label: 'NGN'
}, {
  value: 'nio',
  label: 'NIO'
}, {
  value: 'nok',
  label: 'NOK'
}, {
  value: 'npr',
  label: 'NPR'
}, {
  value: 'nzd',
  label: 'NZD'
}, {
  value: 'omr',
  label: 'OMR'
}, {
  value: 'pab',
  label: 'PAB'
}, {
  value: 'pen',
  label: 'PEN'
}, {
  value: 'pgk',
  label: 'PGK'
}, {
  value: 'php',
  label: 'PHP'
}, {
  value: 'pkr',
  label: 'PKR'
}, {
  value: 'pln',
  label: 'PLN'
}, {
  value: 'pyg',
  label: 'PYG'
}, {
  value: 'qar',
  label: 'QAR'
}, {
  value: 'ron',
  label: 'RON'
}, {
  value: 'rsd',
  label: 'RSD'
}, {
  value: 'rub',
  label: 'RUB'
}, {
  value: 'rwf',
  label: 'RWF'
}, {
  value: 'sar',
  label: 'SAR'
}, {
  value: 'sbd',
  label: 'SBD'
}, {
  value: 'scr',
  label: 'SCR'
}, {
  value: 'sdg',
  label: 'SDG'
}, {
  value: 'sek',
  label: 'SEK'
}, {
  value: 'sgd',
  label: 'SGD'
}, {
  value: 'shp',
  label: 'SHP'
}, {
  value: 'sll',
  label: 'SLL'
}, {
  value: 'sos',
  label: 'SOS'
}, {
  value: 'srd',
  label: 'SRD'
}, {
  value: 'svc',
  label: 'SVC'
}, {
  value: 'syp',
  label: 'SYP'
}, {
  value: 'szl',
  label: 'SZL'
}, {
  value: 'tjs',
  label: 'TJS'
}, {
  value: 'tmt',
  label: 'TMT'
}, {
  value: 'tnd',
  label: 'TND'
}, {
  value: 'top',
  label: 'TOP'
}, {
  value: 'try',
  label: 'TRY'
}, {
  value: 'ttd',
  label: 'TTD'
}, {
  value: 'twd',
  label: 'TWD'
}, {
  value: 'tzs',
  label: 'TZS'
}, {
  value: 'uah',
  label: 'UAH'
}, {
  value: 'ugx',
  label: 'UGX'
}, {
  value: 'uyu',
  label: 'UYU'
}, {
  value: 'uzs',
  label: 'UZS'
}, {
  value: 'vnd',
  label: 'VND'
}, {
  value: 'vuv',
  label: 'VUV'
}, {
  value: 'wst',
  label: 'WST'
}, {
  value: 'xaf',
  label: 'XAF'
}, {
  value: 'xag',
  label: 'XAG'
}, {
  value: 'xau',
  label: 'XAU'
}, {
  value: 'xcd',
  label: 'XCD'
}, {
  value: 'xdr',
  label: 'XDR'
}, {
  value: 'xof',
  label: 'XOF'
}, {
  value: 'xpf',
  label: 'XPF'
}, {
  value: 'yer',
  label: 'YER'
}, {
  value: 'zar',
  label: 'ZAR'
}, {
  value: 'zmw',
  label: 'ZMW'
}, {
  value: 'zwl',
  label: 'ZWL'
}];