import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import { Events, track } from 'components/analytics';
import spendByRegionPicture from './previewPictures/spend-by-region.png';
import cumulativePicture from './previewPictures/cumulative-spend.png';
import costCategoryPicture from './previewPictures/cost-category.png';
import campaignByCategoryPicture from './previewPictures/campaign-by-category.png';
import campaignByDivisionPicture from './previewPictures/campaign-by-division.png';
import { connect } from 'react-redux';
import { getClientSettings, getUser } from 'state/authentication/selectors';
import { chunk, compact, compose } from 'lodash/fp';
import { AccountSetting } from 'cr-core/constants';

const reports = {
  'campaigns-by-division': {
    title: 'Campaigns by Division',
    subtitle: 'What is the budget left, by division?',
    description: [
      'Discover available budget and spend by paying division in seconds.',
      'Immediately spot your overspending campaigns! Compare spend with # deliverables for TVCs Vs digital videos or other estimate types. Customise the data with filters & learn more.',
    ],
    picture: campaignByDivisionPicture,
  },
  'campaigns-by-category': {
    title: 'Campaigns by Category',
    subtitle: 'What is the budget left, by category?',

    description: [
      'Instant clarification on available budget and spend, by category.',
      'Spot your overspending campaigns in seconds! Compare spend with # deliverables for TVCs Vs digital videos or other estimate types. Customise the data with filters & learn more.',
    ],

    picture: campaignByCategoryPicture,
  },
  'cost-categories': {
    title: 'Cost Categories',
    subtitle: 'What are my cost drivers?',
    description: [
      'Get clarity on investment across cost categories and drill down to estimate level to compare spend across productions and campaigns.',
      'Answer instantly questions like how much budget is spent for Shooting Vs Editorial & Post Production, Delivery Services, Talent, Music, etc.',
    ],
    picture: costCategoryPicture, // line items
  },
  'cumulative-spend': {
    title: 'Cumulative Spend',
    subtitle: 'Which are the busiest months?',
    description: [
      'See how much investment is committed at any one time and the status of estimates - draft, pending approval, approved or actualised. You can also spot here opportunities for bundles to save budgets or for efficiently planning your next campaigns!',
    ],
    picture: cumulativePicture, // line chart + calendar
  },
  'lead-market': {
    title: 'Spend by Lead Market',
    subtitle: 'Which markets are busiest?',
    description: [
      'A global view of investment showing how much local markets are investing in productions and campaigns and where campaigns are currently on air. You can also spot here opportunities for bundles to save budgets or for efficiently planning your next campaigns!',
    ],
    picture: spendByRegionPicture, // map
  },
  'top-line-custom': {
    title: 'Top Line Custom Report',
    subtitle: 'Customise and export your own report',
    description: [
      'This Report allows you to create your very own custom report based on the exact data that you would like to see. This report includes all top line information exported in csv which helps you easily see details of campaigns budget and estimated spend, deliverables, estimate creators and approvers, and much more.',
    ],
    picture: '/images/top-line-custom-report.png',
  },
  deliverables: {
    title: 'Additional Deliverables Report',
    subtitle: 'NEW! Customise and export your own deliverables report',
    description: [
      'This is an additional deliverables top line report. Customise your own report based on the available filters, before downloading a csv file with information about number of deliverables per estimate, region, estimate type etc.',
    ],
    picture: '/images/deliverables-report.jpg',
  },
};

const useStyles = makeStyles(theme => {
  return {
    container: {
      padding: theme.spacing(2),
    },
    gridContainer: {
      marginBottom: theme.spacing(2),
    },
    card: {
      borderRadius: 2,
      height: '100%',
    },
    cardActionArea: {
      height: '100%',
      width: '100%',
      display: 'flex',
      textAlign: 'inherit',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      height: 45,
      marginBottom: theme.spacing(2),
    },
    cardTitle: {
      color: theme.palette.grey[500],
      fontSize: '1rem',
      fontWeight: 700,
    },
    cardMedia: {
      minHeight: 200,
      maxHeight: 200,
      background: theme.palette.grey[200],
      backgroundSize: 'cover',
      width: '100%',
      flexGrow: 1,

      [theme.breakpoints.down('md')]: {
        minWidth: 350,
        maxWidth: 350,
      },
    },
    cardContent: {
      flexGrow: 1,
    },
  };
});

const trackClick = path => track(Events.NAV_CLICK, { position: 'reports_page', path });

const ReportDetails = ({ reportId, location }) => {
  const classes = useStyles();
  const report = reports[reportId];

  if (!report) {
    return <Redirect to="/reports" />;
  }

  const { title, subtitle, description, picture } = report;

  return (
    <Link
      to={{ pathname: `/reports/${reportId}`, search: location.search }}
      onClick={() => trackClick(`/reports/${reportId}`)}
    >
      <Card className={classes.card} data-test={`reports:${reportId}Card`}>
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia className={classes.cardMedia} image={picture} title={title} />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h6" className={classes.cardTitle}>
              {title}
            </Typography>
            <Typography gutterBottom variant="h5">
              {subtitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              {description.map((text, index) => (
                <div key={index}>{text}</div>
              ))}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

const ReportsDescriptions = ({ location, user, clientSettings }) => {
  const classes = useStyles();
  const topLineCustomReportEnabled = clientSettings[AccountSetting.TopLineReport];
  const deliverablesReportEnabled = clientSettings[AccountSetting.DeliverablesReport];

  const reportsGrid = chunk(
    3,
    compact([
      deliverablesReportEnabled && 'deliverables',
      topLineCustomReportEnabled && 'top-line-custom',
      'campaigns-by-category',
      'campaigns-by-division',
      'cost-categories',
      'lead-market',
      'cumulative-spend',
    ])
  );

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Welcome to your reports
      </Typography>
      {reportsGrid.map((reportIds, index) => (
        <Grid key={index} container spacing={4} className={classes.gridContainer}>
          {reportIds.map(reportId => {
            if (reportId === 'top-line-custom' && !topLineCustomReportEnabled) {
              return null;
            }

            if (reportId === 'deliverables' && !deliverablesReportEnabled) {
              return null;
            }

            return (
              <Grid key={reportId} item md={12} lg={4}>
                <ReportDetails reportId={reportId} location={location} />
              </Grid>
            );
          })}
        </Grid>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
  clientSettings: getClientSettings(state),
});

export default compose(withRouter, connect(mapStateToProps))(ReportsDescriptions);
